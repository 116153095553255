import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../providers/Auth'
import './style.scss'
import SmartID from '../../components/SmartID'
import IdCard from '../../components/IdCard'
import MobiilID from '../../components/MobiilID'
import UserLogin from '../../components/UserLogin'

const methods = [
    {
        id: 'idcard',
        label: 'ID Card',
        image: './assets/logos/id-card-small.webp',
    },
    {
        id: 'mobiilid',
        label: 'Mobiil ID',
        image: './assets/logos/mobiil-id-small.webp',
    },
    {
        id: 'smartid',
        label: 'Smart ID',
        image: './assets/logos/smart-id-small.svg',
    },
]

const LoginScreen = () => {

    const { t } = useTranslation()
    const { user } = useAuth()
    const navigate = useNavigate()
    const [method, setMethod] = useState()

    useEffect(() => {
        if (user) {
            navigate('/', {
                replace: true,
            })
        }
    }, [])

    const selectMethod = (id) => {
        setMethod(id)
    }

    const handleCancel = () => {
        setMethod(null)
    }

    const renderMethod = () => {
        if (method === 'idcard') return (<IdCard onCancel={handleCancel} />)
        if (method === 'smartid') return (<SmartID onCancel={handleCancel} />)
        if (method === 'mobiilid') return (<MobiilID onCancel={handleCancel} />)
        if (method === 'user') return (<UserLogin onCancel={handleCancel} />)
    }

    return (
        <div className='login'>
            <div className='inner'>
                <div className='brand'>
                    <div className='brand-logo'></div>
                    <div className='eaal-logo'></div>
                </div>

                {
                    !method ?
                    <>
                        <p>{ t('login.choose_method') }</p>
                        <div className='methods'>
                            {
                                methods.map((item, index) =>
                                    <div
                                        key={`method-${index}`}
                                        className={`methods-item${method === index ? ' methods-item--selected' : ''}`}
                                        onClick={() => selectMethod(item.id)}
                                    >
                                        <div className='methods-item--image'>
                                            <img src={item.image} />
                                        </div>
                                        <h4>{ item.label }</h4>
                                    </div>
                                )
                            }
                        </div>
                        <div className='methods-user'>
                            <a onClick={() => selectMethod('user')}>{ t('login.with_password') }</a>
                        </div>
                    </>
                    :
                    renderMethod()
                }
            </div>
        </div>
    )
}

export default LoginScreen