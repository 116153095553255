import React from 'react'
import { Dots } from 'react-activity'

const ActivityIndicator = () => {

    return (
        <Dots color='#71088D' speed={0.5} />
    )
}

export default ActivityIndicator