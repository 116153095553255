import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../providers/Auth'
import Button from './Button'
import { UserRoles } from '../util/const'

const AppHeader = () => {

    const { t } = useTranslation()
    const { user, signOut } = useAuth()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [navOpen, setNavOpen] = useState(false)
    const [subsMenus, setSubMenus] = useState({
        creations: false,
        reports: false,
        bonuses: false,
    })

    const toggleMenu = () => setNavOpen(!navOpen)
    const closeMenu = () => setNavOpen(false)

    const renderAdminMenu = () => {
        return (
            <>
                <div className={`nav-sub${subsMenus.material ? ' nav-sub--open' : ''}`}>
                    <a
                        className={['/creations', '/unrecognized', '/views'].includes(pathname) ? 'active' : ''}
                        onClick={() => {
                            setSubMenus({ ...subsMenus, creations: !subsMenus.creations })
                            navigate('/creations')
                        }}
                    >
                        { t('header.creations') }
                    </a>
                    <div className='nav-sub--dropdown'>
                        <Link onClick={closeMenu} className={pathname === '/unrecognized' ? 'active' : ''} to={'/unrecognized'}>
                            { t('header.unrecognized') }
                        </Link>
                    </div>
                </div>
                <div className={`nav-sub${subsMenus.reports ? ' nav-sub--open' : ''}`}>
                    <a
                        className={['/reports', '/authors-reports'].includes(pathname) ? 'active' : ''}
                        onClick={() => {
                            setSubMenus({ ...subsMenus, reports: !subsMenus.reports })
                            navigate('/reports')
                        }}
                    >
                        { t('header.reports') }
                    </a>
                    <div className='nav-sub--dropdown'>
                        <Link onClick={closeMenu} className={pathname === '/authors-reports' ? 'active' : ''} to={'/authors-reports'}>
                            { t('header.author_reports') }
                        </Link>
                        <Link onClick={closeMenu} className={pathname === '/views' ? 'active' : ''} to={'/views'}>
                            { t('header.views') }
                        </Link>
                    </div>
                </div>
                <Link onClick={closeMenu} className={pathname === '/users' ? 'active' : ''} to={'/users'}>
                    { t('header.users') }
                </Link>
                <div className={`nav-sub${subsMenus.bonuses ? ' nav-sub--open' : ''}`}>
                    <a
                        className={['/budget', '/bonuses'].includes(pathname) ? 'active' : ''}
                        onClick={() => {
                            setSubMenus({ ...subsMenus, bonuses: !subsMenus.bonuses })
                            navigate('/budget')
                        }}
                    >
                        { t('header.budget') }
                    </a>
                    <div className='nav-sub--dropdown'>
                        <Link onClick={closeMenu} className={pathname === '/bonuses' ? 'active' : ''} to={'/bonuses'}>
                            { t('header.bonuses') }
                        </Link>
                        <Link onClick={closeMenu} className={pathname === '/foreign' ? 'active' : ''} to={'/foreign'}>
                            { t('header.foreign') }
                        </Link>
                    </div>
                </div>
                <Link onClick={closeMenu} className={pathname === '/societies' ? 'active' : ''} to={'/societies'}>
                    { t('header.societies') }
                </Link>
            </>
        )
    }

    const renderUserMenu = () => {
        return <></>
        if (!user || !user.selectedRole) return (<></>)
        return (
            <>
                <div className={`nav-sub${subsMenus.reports ? ' nav-sub--open' : ''}`}>
                    <a
                        className={['/user-reports'].includes(pathname) ? 'active' : ''}
                        onClick={() => {
                            setSubMenus({ ...subsMenus, reports: !subsMenus.reports })
                            navigate(`/user-reports/${user.selectedRole}`)
                        }}
                    >
                        { t('header.reports') }
                    </a>
                </div>
            </>
        )
    }

    return (
        <header className={`app-header${navOpen ? ' nav-open' : ''}`}>
            <div className='app-header--inner'>
                <div className='brand'>
                    <div onClick={() => navigate('/')} className='brand-logo'></div>
                    <div onClick={() => navigate('/')} className='eaal-logo'></div>
                </div>
                <nav>
                    {
                        user?.role === UserRoles.Admin ?
                        renderAdminMenu()
                        :
                        renderUserMenu()
                    }
                    {
                        user &&
                        <>
                            <div className='nav-sub'>
                                <div className='app-header--info'>
                                    <span className='app-header--info-name'>{ `${(user.firstName).toLowerCase()} ${user.lastName ? (user.lastName).toLowerCase() : ''}${user.role === 'ADMIN' ? ' (Admin)' : ''}` }</span>
                                </div>
                                <div className='nav-sub--dropdown'>
                                    <Link to={'/profile'}>
                                        { t('header.profile') }
                                    </Link>
                                </div>
                            </div>
                            <div className='app-header--actions'>
                                <Button
                                    className={'btn-small'}
                                    label={t('header.sign_out')}
                                    onClick={signOut}
                                />
                            </div>
                        </>
                    }
                </nav>
                <div className="menu-btn" onClick={toggleMenu}>
                    <div></div>
                </div>
            </div>
        </header>
    )
}

export default AppHeader