import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Input from './Input'
import SelectInput from './SelectInput'
import { UserRoles } from '../util/const'
import SearchIcon from './icons/search'

const initialState = {
    query: null,
    role: null,
}

const roles = [
    {
        value: null,
        label: 'users.all',
    },
    {
        value: UserRoles.Admin,
        label: 'users.admin',
    },
    {
        value: UserRoles.Client,
        label: 'users.client',
    },
    {
        value: UserRoles.Member,
        label: 'users.member',
    },
]

const UserFilters = ({
    onFilterChange,
}) => {

    const { t } = useTranslation()
    const [filters, setFilters] = useState(initialState)

    useEffect(() => {
        if (onFilterChange) onFilterChange(filters)
    }, [])

    const setField = (field, value) => {
        const newVal = {
            ...filters,
            [field]: value,
        }

        setFilters(newVal)

        if (onFilterChange) onFilterChange(newVal)
    }

    return (
        <div className='filters filters-user'>
            <div className='filters-search'>
                <div className='filters-search--query'>
                    <Input
                        label={ t('users.search_user') }
                        placeholder={ t('users.search_name_pid_ipi') }
                        onChange={(e) => setField('query', e.target.value)}
                        value={filters.query}
                        icon={<SearchIcon />}
                    />
                </div>
                <div className='filters-search--role'>
                    <SelectInput
                        label={ t('users.search_role') }
                        options={roles}
                        value={roles.find(x => x.value === filters.role)}
                        getOptionLabel={(option) => t(option.label)}
                        onChange={(option) => setField('role', option.value)}
                    />
                </div>
            </div>
        </div>
    )
}

export default UserFilters