import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../providers/Auth'
import { useQuery } from '@apollo/client'
import './style.scss'
import ActivityIndicator from '../../components/ActivityIndicator'
import { GetProfile } from '../../graphql/queries'
import Button from '../../components/Button'

const SelectRoleScreen = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const [profile, setProfile] = useState()
    const { selectRole } = useAuth()

    const { loading } = useQuery(GetProfile, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (!data?.getProfile) return
            setProfile(data.getProfile)
        },
    })

    const handleSelectRole = (role, authorId) => {
        selectRole(role)
        navigate(`/user-tabs/${role}${authorId ? `/${authorId}` : ''}`)
    }

    if (loading) return <ActivityIndicator />

    return (
        <div className='select-role'>
            <div className='inner'>
                <div className='select-role-content'>
                    
                    {
                        profile?.ipi ?
                        <>
                            <h4>{ t('select.author') }</h4>
                            <Button
                                label={ profile.name }
                                onClick={() => handleSelectRole('author')}
                            />
                        </>
                        :
                        <></>
                    }
                    {
                        profile?.authors?.length > 0 ?
                        <>
                            <h4>{ t('select.heir') }</h4>
                            <div className='select-role--items'>
                            {
                                profile.authors.map(item =>
                                    <Button
                                        key={item.id}
                                        label={ item.author.name }
                                        onClick={() => handleSelectRole('heir', item.author.id)}
                                    />    
                                )
                            }
                            </div>
                        </>
                        :
                        <></>
                    }
                </div>
            </div>
        </div>
    )
}

export default SelectRoleScreen