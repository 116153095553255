import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { capitalizeName } from '../util/string'
import Button from './Button'
import CreatableInput from './CreatableInput'
import PlusIcon from './icons/plus'
import Input from './Input'
import SelectInput from './SelectInput'
import { GetAuthorRoles, SearchSocieties, SearchUsers } from '../graphql/queries'
import { AddCreationAuthor, EditCreationAuthor, RemoveCreationAuthor } from '../graphql/mutations'
import { useNavigate, useParams } from 'react-router-dom'

const authorState = {
    authorName: null,
    authorRole: null,
    ipi: null,
    authorSociety: null,
}

const initialErrorsState = {
    authorName: null,
    ipi: null,
    authorSociety: null,
    authorRole: null,
}

const Authors = ({
    data,
    onRefresh,
}) => {
    const { t } = useTranslation()
    const { id } = useParams()
    const navigate = useNavigate()

    const [items, setItems] = useState(data)
    const [roles, setRoles] = useState([])
    const [errors, setErrors] = useState(initialErrorsState)

    const [newAuthor, setNewAuthor] = useState(authorState)
    const [addAuthor] = useMutation(AddCreationAuthor)
    const [editAuthor] = useMutation(EditCreationAuthor)
    const [removeAuthor] = useMutation(RemoveCreationAuthor)

    const { refetch: searchSocieties } = useQuery(SearchSocieties, {
        skip: true,
    })

    useQuery(GetAuthorRoles, {
        onCompleted: (data) => {
            if (!data?.getAuthorRoles) return
            setRoles(data.getAuthorRoles)
        },
    })

    const { refetch: searchUsers } = useQuery(SearchUsers, {
        skip: true,
    })

    useEffect(() => {
        setItems(data)
    }, [data])

    const handleAuthorClick = (user) => {
        if (!user?.id) return
        navigate(`/user/${user.id}`)
    }

    const loadSocieties = async (input) => {
        const res = await searchSocieties({
            input,
        })
        return res?.data?.searchSocieties
    }

    const handleAddAuthor = async () => {
        if (hasInputErrors()) return
        try {
            await addAuthor({
                variables: {
                    data: {
                        ...newAuthor,
                        creationId: parseInt(id),
                    },
                },
            })

            setNewAuthor({
                ...authorState,
            })
            if (onRefresh) onRefresh()
        } catch (err) {
            console.log('addAuthor: ', err)
        }
    }

    const handleEditAuthor = async (authorId, field, value) => {
        try {
            const list = [...items]
            const index = list.findIndex(x => x.id === authorId)
            list[index] = {
                ...list[index],
                [field]: value,
            }

            setItems([...list])

            await editAuthor({
                variables: {
                    id: parseInt(authorId),
                    data: {
                        [field]: field === 'authorSociety' ? parseInt(value) : value,
                    },
                },
            })
        } catch (err) {
            console.log('editAuthor: ', err)
        }
    }

    const handleRemoveAuthor = async (authorId) => {
        try {
            await removeAuthor({
                variables: {
                    id: parseInt(authorId),
                },
            })

            if (onRefresh) onRefresh()
        } catch (err) {
            console.log('removeAuthor: ', err)
        }
    }

    const handleSelectAuthor = (option) => {
        setErrors({
            ...errors,
            authorName: null,
            ipi: null,
        })

        if (!option) {
            setNewAuthor({
                ...newAuthor,
                authorName: null,
                ipi: null,
            })
            return
        }
        if (option.__isNew__) {
            setNewAuthor({
                ...newAuthor,
                authorName: option.value,
                ipi: '',
            })
            return
        }
        setNewAuthor({
            ...newAuthor,
            authorName: option.name,
            ipi: option.ipi,
        })
    }

    const loadUsers = async (input) => {
        if (input.length < 2) return
        const res = await searchUsers({
            input,
        })
        return res?.data?.searchUsers
    }

    const handleSelectSociety = (option) => {
        setErrors({
            ...errors,
            authorSociety: null,
        })
        if (!option) {
            setNewAuthor({
                ...newAuthor,
                authorSociety: null,
            })
            return
        }
        if (option.__isNew__) {
            setNewAuthor({
                ...newAuthor,
                authorSociety: parseInt(option.value),
            })
            return
        }
        setNewAuthor({
            ...newAuthor,
            authorSociety: parseInt(option.code),
        })
    }

    const hasInputErrors = () => {
        let hasErrors = false
        const errorState = {
            ...initialErrorsState,
        }

        if (!newAuthor.authorName) {
            hasErrors = true
            errorState.authorName = t('reports.fill_field')
        }

        if (!newAuthor.authorSociety) {
            hasErrors = true
            errorState.authorSociety = t('reports.fill_field')
        }

        if (!newAuthor.authorRole) {
            hasErrors = true
            errorState.authorRole = t('reports.fill_field')
        }

        setErrors({
            ...errorState,
        })
        return hasErrors
    }

    const setField = (field, value) => {
        setErrors({
            ...errors,
            [field]: null,
        })
        setNewAuthor({
            ...newAuthor,
            [field]: value,
        })
    }

    return (
        <div className='creation-authors'>
            <h3>{ t('creation.authors') }</h3>
            {
                items ?
                items.map((author, index) =>
                    <div
                        key={author.id}
                        className={`creation-authors--item${author?.user?.id ? ' creation-authors--item-user' : ''}`}
                        onClick={() => handleAuthorClick(author.user)}
                    >
                        <div className='creation-authors--item-info'>
                            <div className='creation-authors--item-name'>
                                { capitalizeName(author.authorName) }
                            </div>
                            <div className='creation-authors--item-role'>
                                { t(author.role.name) }
                            </div>
                            <div className='creation-authors--item-meta'>
                                <Input
                                    label={t('IPI NN')}
                                    type={'number'}
                                    value={author.ipi}
                                    onChange={(e) => handleEditAuthor(author.id, 'ipi', e.target.value)}
                                />
                                <Input
                                    label={t('SOC')}
                                    type={'number'}
                                    value={author.authorSociety}
                                    onChange={(e) => handleEditAuthor(author.id, 'authorSociety', e.target.value)}
                                />
                            </div>
                        </div>
                        <Button
                            className={'btn-small'}
                            label={t('creation.remove_author')}
                            onClick={() => handleRemoveAuthor(author.id)}
                        />
                    </div>
                )
                :
                <p>{ t('creation.no_authors') }</p>
            }
            <div className='creation-authors--add'>
                <h4>{ t('creation.add_author') }</h4>
                <CreatableInput
                    placeholder={t('creation.add_author')}
                    load={loadUsers}
                    getOptionLabel={(option) => option.name || option.label}
                    getOptionValue={(option) => option.id || option.value}
                    onChange={handleSelectAuthor}
                    loadingMessage={() => t('users.loading_users')}
                    noOptionsMessage={() => t('users.type_to_search')}
                    isClearable={true}
                    error={errors.authorName}
                />
                <Input
                    label={t('creation.author_ipi')}
                    value={ newAuthor.ipi }
                    type={'number'}
                    onChange={(e) => setField('ipi', e.target.value)}
                    error={errors.ipi}
                />
                <SelectInput
                    placeholder={t('creation.author_role')}
                    options={roles}
                    value={roles.find(x => x.id === newAuthor?.authorRole)}
                    getOptionLabel={(option) => t(option.name)}
                    getOptionValue={(option) => option.id}
                    onChange={(option) => setField('authorRole', option.id)}
                    error={errors.authorRole}
                />
                <CreatableInput
                    getOptionLabel={(option) => option.name || option.label}
                    getOptionValue={(option) => option.code || option.value}
                    onChange={handleSelectSociety}
                    isClearable={true}
                    placeholder={t('filters.society')}
                    label={ t('filters.society_label') }
                    load={loadSocieties}
                    loadingMessage={() => t('societies.loading')}
                    noOptionsMessage={() => t('societies.type_to_search')}
                    error={errors.authorSociety}
                />
                <Button
                    label={t('creation.add')}
                    onClick={handleAddAuthor}
                    icon={<PlusIcon />}
                />
            </div>
        </div>
    )
}

export default Authors