import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import Input from './Input'
import { GetViewFilters } from '../graphql/queries'
import SelectInput from './SelectInput'
import SearchIcon from './icons/search'

const initialState = {
    query: null,
    year: null,
    distribution: null,
}

const ViewsFilters = forwardRef(({
    onFilterChange,
}, ref) => {

    const { t } = useTranslation()
    const [filters, setFilters] = useState(initialState)
    const [years, setYears] = useState([])
    const [distributions, setDistributions] = useState([])
    
    const { refetch } = useQuery(GetViewFilters, {
        onCompleted: (data) => {
            if (data?.getViewFilters) {
                if (data.getViewFilters.years) {
                    const yearList = data.getViewFilters.years.map(x => ({
                        value: x,
                        label: x,
                    }))
                    setYears(yearList)
                }

                if (data.getViewFilters.distributions) {
                    const distList = data.getViewFilters.distributions.map(x => ({
                        value: x,
                        label: x,
                    }))
                    
                    setDistributions(distList)
                }                
            }
        },
    })

    useEffect(() => {
        if (onFilterChange) onFilterChange(filters)
    }, [])

    useEffect(() => {
        if (years.length <= 0) return
        setField('year', parseInt(years[years.length - 1].value))
    }, [years])

    useImperativeHandle(ref, () => ({
        refresh () {
            refetch()
        },
    }))

    const setField = (field, value) => {
        const newVal = {
            ...filters,
            [field]: value,
        }

        setFilters(newVal)

        if (onFilterChange) onFilterChange(newVal)
    }

    return (
        <div className='filters'>
            <div className='filters-search'>
                <Input
                    placeholder={ t('filters.search_views') }
                    label={ t('filters.search_label') }
                    onChange={(e) => setField('query', e.target.value)}
                    value={filters.query}
                    icon={<SearchIcon />}
                />
            </div>
            <SelectInput
                options={years}
                onChange={(option) => setField('year', parseInt(option.value))}
                value={years.find(x => parseInt(x.value) === filters.year)}
                placeholder={t('filters.year')}
                label={ t('filters.year_label') }
            />
            <SelectInput
                options={distributions}
                onChange={(option) => setField('distribution', option ? option.value : null)}
                value={distributions.find(x => x.value === filters.distribution)}
                isClearable={true}
                placeholder={t('filters.distribution')}
                label={ t('filters.distribution_label') }
            />
        </div>
    )
})

export default ViewsFilters