import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import FileUpload from './FileUpload'
import Modal from './Modal'
import { ImportCreations } from '../graphql/mutations'

const ImportCreationsModal = ({
    show,
    close,
    onSuccess,
    onFail,
}) => {

    const { t } = useTranslation()
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const [importCreations] = useMutation(ImportCreations)

    const handleImport = async (file) => {
        setLoading(true)
        
        try {
            const res = await importCreations({
                variables: {
                    file,
                },
            })

            if (res?.data?.importCreations?.length > 0) {
                const failList = res.data.importCreations.map(x => `(${x.error}) Title: ${x.title}, Year: ${x.year}, Episode: ${x.episode}, Type: ${x.type}`)
                setError(`${t('creations.import_error_list')}\n\n${failList.join('\n\n')}`)
                if (onFail) onFail()
                return
            }

            if (onSuccess) onSuccess()
        } catch (err) {
            setError(t('creations.import_error'))
            if (onFail) onFail()
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const handleClose = () => {
        if (close) close()
        setError(null)
    }

    return (
        <Modal
            className={'modal-society'}
            show={show}
            close={handleClose}
            title={ t('creations.import') }
        >
            <FileUpload
                handleUpload={handleImport}
                loading={loading}
            />

            {
                error ?
                <div className='error-message'>
                    <span>{ error }</span>
                </div>
                :
                <></>
            }
        </Modal>
    )
}

export default ImportCreationsModal