import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { GetReportFilters, SearchSocieties } from '../graphql/queries'
import SelectInput from './SelectInput'
import { ReportTypes } from '../util/const'
import Input from './Input'
import Checkbox from './Checkbox'
import SearchIcon from './icons/search'

const types = [
    {
        label: 'Kõik',
        value: 'all',
    },
    {
        label: 'TV',
        value: ReportTypes.TV,
    },
    {
        label: 'VOD',
        value: ReportTypes.VOD,
    },
]

const initialState = {
    type: ReportTypes.TV,
    year: null,
    distribution: null,
    query: '',
    society: null,
    foreign: false,
    bonus: false,
}

const ExportReportFilters = forwardRef(({
    onFilterChange,
    onLoaded,
    allowed,
}, ref) => {

    const { t } = useTranslation()
    const [filters, setFilters] = useState(initialState)
    const [years, setYears] = useState([])
    const [societies, setSocieties] = useState([])
    const [distributions, setDistributions] = useState([])
    const [query, setQuery] = useState('')
    const { refetch: searchSocieties } = useQuery(SearchSocieties, {
        skip: true,
    })
    
    const { loading, refetch } = useQuery(GetReportFilters, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data?.getReportFilters) {
                if (data.getReportFilters.years) {
                    const yearList = data.getReportFilters.years.map(x => ({
                        value: x,
                        label: x,
                    }))
                    
                    setYears(yearList)
                    if (yearList?.length > 0) setField('year', parseInt(yearList[yearList.length - 1].value))
                }

                if (data.getReportFilters.distributions) {
                    const distList = data.getReportFilters.distributions.map(x => ({
                        value: x,
                        label: x,
                    }))
                    setDistributions(distList)
                }


                if (data.getReportFilters.societies) {
                    const socList = data.getReportFilters.societies.map(x => ({
                        value: x.code,
                        label: x.name,
                    }))
                    setSocieties(socList)
                }
            }
        },
    })

    useEffect(() => {
        if (onFilterChange) onFilterChange(filters)
    }, [])

    useEffect(() => {
        if (!loading && onLoaded) onLoaded() 
    }, [loading])

    useEffect(() => {
        if (query && query.length < 3) return
        if (onFilterChange) onFilterChange({
            ...filters,
            query,
        })
    }, [query])

    useImperativeHandle(ref, () => ({
        refresh () {
            refetch()
        },
    }))

    const loadSocieties = async (input) => {
        const res = await searchSocieties({
            input,
        })
        return res?.data?.searchSocieties
    }

    const setField = (field, value) => {
        const newVal = {
            ...filters,
            [field]: value,
            query: query?.length > 0 ? query : '',
        }

        setFilters(newVal)

        if (onFilterChange) onFilterChange(newVal)
    }

    return (
        <>
            <div className='filters'>
                {
                    !allowed?.query ?
                    <></>
                    :
                    <div className='filters-search--query'>
                        <Input
                            placeholder={ t('filters.search') }
                            label={ t('filters.search_label') }
                            onChange={(e) => setQuery(e.target.value)}
                            value={query}
                            icon={<SearchIcon />}
                        />
                    </div>
                }
                {
                    !allowed?.year ?
                    <></>
                    :
                    <SelectInput
                        options={years}
                        onChange={(option) => setField('year', parseInt(option.value))}
                        value={years.find(x => parseInt(x.value) === filters.year)}
                        placeholder={t('filters.year')}
                        label={ t('filters.year_label') }
                    />
                }
                {
                    !allowed?.distribution ?
                    <></>
                    :
                    <SelectInput
                        options={distributions}
                        onChange={(option) => setField('distribution', option ? option.value : null)}
                        value={distributions.find(x => x.value === filters.distribution)}
                        isClearable={true}
                        placeholder={t('filters.distribution')}
                        label={ t('filters.distribution_label') }
                    />
                }
                {
                    !allowed?.type ?
                    <></>
                    :
                    <SelectInput
                        placeholder={ t('filters.type') }
                        options={types}
                        value={types.find(x => x.value === filters.type)}
                        getOptionLabel={(option) => t(option.label)}
                        onChange={(option) => setField('type', option.value)}
                        label={ t('filters.type_label') }
                    />
                }
                {
                    !allowed?.society ?
                    <></>
                    :
                    <SelectInput
                        placeholder={t('filters.society')}
                        label={ t('filters.society_label') }
                        load={loadSocieties}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.code}
                        onChange={(option) => setField('society', option.code)}
                        loadingMessage={() => t('societies.loading')}
                        noOptionsMessage={() => t('societies.type_to_search')}
                    />
                }
            </div>
            {
                allowed.foreign || allowed.bonus ?
                <div className='filters-extra'>
                    <h4>{ t('reports.filters_extra') }</h4>
                    <div className='filters-extra--items'>
                        {
                            !allowed?.foreign ?
                            <></>
                            :
                            <Checkbox
                                label={`${t('filters.foreign')}`}
                                value={filters.foreign}
                                onChange={(e) => setField('foreign', e.target.checked)}
                            />
                        }
                        {
                            !allowed?.bonus ?
                            <></>
                            :
                            <Checkbox
                                label={`${t('filters.bonuses')}`}
                                value={filters.bonus}
                                onChange={(e) => setField('bonus', e.target.checked)}
                            />
                        }
                    </div>
                </div>
                :
                <></>
            }
        </>
    )
})

export default ExportReportFilters