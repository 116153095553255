import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { GetViews } from '../../graphql/queries'
import './style.scss'
import FilterTable from '../../components/FilterTable'
import Button from '../../components/Button'
import ImportIcon from '../../components/icons/import'
import ViewsFilters from '../../components/ViewsFilters'
import CreateViewsModal from '../../components/CreateViewsModal'
import { RemoveViews } from '../../graphql/mutations'
import Tasks from '../../components/Tasks'
import { TaskTypes } from '../../util/const'
import { capitalizeName } from '../../util/string'
import { useNavigate } from 'react-router-dom'

const includeFields = [
    {
        value: 'distribution',
        label: 'Jaotus',
    },
    {
        value: 'year',
        label: 'Aasta',
    },
    {
        value: 'creationTitle',
        label: 'Teos',
    },
    {
        value: 'creationSeason',
        label: 'Hooaeg',
    },
    {
        value: 'creationEpisode',
        label: 'Seeria',
    },
]

const ViewsScreen = () => {

    const { t } = useTranslation()
    const tableRef = useRef()
    const filtersRef = useRef()
    const navigate = useNavigate()

    const [removeViews] = useMutation(RemoveViews)
    const [extraFilters, setExtraFilters] = useState()
    const [showAddModal, setShowAddModal] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])

    const fieldConditions = (row, field) => {
        if (field === 'creationTitle' && row?.creation?.title) return capitalizeName(row.creation.title)
        if (field === 'creationSeason' && row?.creation?.season) return row.creation.season
        if (field === 'creationEpisode' && row?.creation?.episode) return row.creation.episode
        return row[field]
    }

    const handleRowClick = (selection) => {
        if (selectedRows.length === 1 && selectedRows[0] === selection) {
            setSelectedRows([])
            return
        }
        if (Array.isArray(selection)) {
            setSelectedRows(selection)
        } else {
            setSelectedRows([selection])
        }
    }

    const handleFilterChange = async (filters) => {
        setExtraFilters(filters)
    }

    const handleSuccess = () => {
        setShowAddModal(false)
        tableRef.current?.refresh()
        filtersRef.current?.refresh()
    }

    const handleDelete = async () => {
        try {
            const res = await removeViews({
                variables: {
                    ids: selectedRows.map(x => x.id),
                },
            })

            if (res?.data?.removeViews === 'SUCCESS') {
                tableRef.current?.refresh()
                filtersRef.current?.refresh()
            }

            console.log('Delete views:', res)
        } catch (err) {
            console.log('handleDelete', err)
        }
    }

    const handleRowDoubleClick = (row) => {
        navigate(`/creation/${row.creation.id}`)
    }

    return (
        <div className='views'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ t('views.title') }</h1>
                    <Button
                        label={ t('views.add_new') }
                        onClick={() => setShowAddModal(true)}
                        icon={<ImportIcon />}
                    />
                    {
                        selectedRows.length > 0 ?
                        <Button
                            label={ t('views.delete') }
                            onClick={handleDelete}
                        />
                        :
                        <></>
                    }
                </div>
                <Tasks
                    type={TaskTypes.ViewImport}
                />
                <ViewsFilters
                    ref={filtersRef}
                    onFilterChange={handleFilterChange}
                />
                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={GetViews}
                        fieldConditions={fieldConditions}
                        queryKey={'getViews'}
                        hideInputs={true}
                        initialOrderBy={'year'}
                        onRowClick={handleRowClick}
                        onRowDoubleClick={handleRowDoubleClick}
                        includeFields={includeFields}
                        extraFilters={extraFilters}
                        useExtraFilters={true}
                        activeRows={selectedRows}
                        allowSelect={true}
                    />
                </div>
            </div>
            <CreateViewsModal
                show={showAddModal}
                close={() => setShowAddModal(false)}
                onSuccess={handleSuccess}
            />
        </div>
    )
}

export default ViewsScreen