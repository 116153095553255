import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import Button from './Button'
import Input from './Input'
import Modal from './Modal'
import { CreateReport } from '../graphql/mutations'
import SelectInput from './SelectInput'
import ErrorMessage from './ErrorMessage'
import SuccessMessage from './SuccessMessage'
import { GetBudgets } from '../graphql/queries'

const initialState = {
    year: '',
    type: '',
    country: '',
}

const initialErrorsState = {
    year: null,
    type: null,
    country: null,
    budget: null,
    generic: null,
}

const CreateReportModal = ({
    show,
    close,
    onSuccess,
    filters,
}) => {

    const { t } = useTranslation()
    const [item, setItem] = useState(initialState)
    const [errors, setErrors] = useState(initialErrorsState)
    const [success, setSuccess] = useState(null)
    const [loading, setLoading] = useState(false)
    const [budgets, setBudgets] = useState([])
    const [addReport] = useMutation(CreateReport)

    useQuery(GetBudgets, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data?.getBudgets) {
                setBudgets(data.getBudgets)
            }
        },
    })

    const handleClose = () => {
        setErrors(initialErrorsState)
        setItem(initialState)
        setLoading(false)
        setBudgets([])
        
        if (close) close()
    }

    const hasInputErrors = () => {
        let hasErrors = false
        const errorState = {
            ...initialErrorsState,
        }

        if (!item.year) {
            hasErrors = true
            errorState.year = t('reports.fill_field')
        }

        if (!item.type) {
            hasErrors = true
            errorState.type = t('reports.fill_field')
        }

        const budget = budgets.find(x => x.year === item.year)

        if (getBudget() <= 0 || budget.payed) {
            hasErrors = true
            errorState.budget = t('reports.budget_undefined')
        }

        setErrors({
            ...errorState,
        })
        return hasErrors
    }

    const handleAdd = async () => {
        if (hasInputErrors()) return
        setLoading(true)
        try {
            const { data } = await addReport({
                variables: {
                    type: item.type,
                    year: parseInt(item.year),
                },
            })

            console.log(data)

            if (!data?.createReport) {
                setErrors({
                    ...errors,
                    generic: t('reports.add_error')
                })
                return
            }

            if (data.createReport.message === 'EXISTING') {
                setErrors({
                    ...errors,
                    generic: t('reports.existing_error')
                })
                return
            }

            if (data.createReport.message === 'FAIL' && data?.createReport?.failed?.length > 0) {
                const failList = data.createReport.failed.map(x => `(${x.error}) Name: ${x.distribution}, CreationID: ${x.creationId}`)
                setErrors({
                    ...errors,
                    generic: `${t('reports.add_error')}\n\n${failList.join('\n\n')}`
                })
                return
            }

            if (data.createReport.message === 'SUCCESS') {
                if (data?.createReport?.failed?.length > 0) {
                    const failList = data.createReport.failed.map(x => `(${x.error}) Name: ${x.distribution}, CreationID: ${x.creationId}`)
                    setSuccess(`${t('reports.success')}\n\n${failList.join('\n\n')}`)
                    return
                }
                setSuccess(`${t('reports.success')}`)
            }
        } catch (err) {
            if (err.message === 'MISSING VIEWS') {
                setErrors({
                    ...errors,
                    generic: t('reports.missing_views')
                })
                return
            }
            setErrors({
                ...errors,
                generic: t('reports.add_error')
            })
        } finally {
            setLoading(false)
        }
    }

    const getBudget = () => {
        const budget = budgets.find(x => x.year === item.year)
        if (item.type === 'TV') return budget?.tvBudget ? budget.tvBudget : '0'
        return budget?.vodBudget ? budget.vodBudget : '0'
    }

    return (
        <Modal
            className={'modal-report'}
            show={show}
            close={handleClose}
            title={ t('reports.add_new') }
        >
            <SelectInput
                label={ t('reports.year') }
                options={filters.years}
                value={filters.years.find(x => x.value === item.year)}
                getOptionLabel={(option) => t(option.label)}
                onChange={(option) => setItem({ ...item, year: option.value })}
                error={errors.year}
            />
            <SelectInput
                label={ t('reports.type') }
                options={filters.types}
                value={filters.types.find(x => x.value === item.type)}
                getOptionLabel={(option) => t(option.label)}
                onChange={(option) => setItem({ ...item, type: option.value })}
                error={errors.type}
            />

            {
                budgets
                && budgets.length > 0
                && item.year
                && item.type ?
                <div className='budget'>
                    <span>{ `${t('reports.distribution_amount')} ${getBudget()} EUR` }</span>
                </div>
                :
                <></>
            }

            <ErrorMessage message={errors?.generic || errors?.budget} />
            <SuccessMessage message={success} />

            <Button
                label={ t('reports.create') }
                onClick={handleAdd}
                loading={loading}
            />
        </Modal>
    )
}

export default CreateReportModal