import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { GetUnrecognized } from '../../graphql/queries'
import './style.scss'
import FilterTable from '../../components/FilterTable'
import DeleteIcon from '../../components/icons/delete'
import Button from '../../components/Button'
import CreateViewsModal from '../../components/CreateViewsModal'
import { AddToBlacklist, RemoveUnrecognized } from '../../graphql/mutations'
import UnrecognizedFilters from '../../components/UnrecognizedFilters'
import { useNavigate } from 'react-router-dom'

const includeFields = [
    {
        value: 'title',
        label: 'Pealkiri',
    },
    {
        value: 'originalTitle',
        label: 'Originaalpealkiri',
    },
    {
        value: 'year',
        label: 'Aasta',
    },
    {
        value: 'season',
        label: 'Hooaeg',
    },
    {
        value: 'episode',
        label: 'Seeria',
    },
    {
        value: 'country',
        label: 'Riik',
    },
    {
        value: 'producer',
        label: 'Tootja',
    },
]

const UnrecognizedScreen = () => {

    const { t } = useTranslation()

    const tableRef = useRef()
    const filtersRef = useRef()
    const navigate = useNavigate()

    const [removeUnrecognized] = useMutation(RemoveUnrecognized)
    const [addDeny] = useMutation(AddToBlacklist)
    const [extraFilters, setExtraFilters] = useState()
    const [showAddModal, setShowAddModal] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])

    const fieldConditions = (row, field) => {
        return row[field]
    }

    const handleRowClick = (selection) => {
        if (selectedRows.length === 1 && selectedRows[0] === selection) {
            setSelectedRows([])
            return
        }
        if (Array.isArray(selection)) {
            setSelectedRows(selection)
        } else {
            setSelectedRows([selection])
        }
    }

    const handleFilterChange = async (filters) => {
        setExtraFilters(filters)
    }

    const handleSuccess = () => {
        setShowAddModal(false)
        tableRef.current?.refresh()
        filtersRef.current?.refresh()
    }

    const handleDelete = async () => {
        try {
            const res = await removeUnrecognized({
                variables: {
                    ids: selectedRows.map(x => x.id),
                },
            })

            if (res?.data?.removeUnrecognized === 'SUCCESS') {
                tableRef.current?.refresh()
                filtersRef.current?.refresh()
            }

            console.log('Delete unrecognized:', res)
        } catch (err) {
            console.log('handleDelete', err)
        }
    }

    const handleAddDeny = async () => {
        try {
            const input = selectedRows.map(x => ({
                id: parseInt(x.id),
                title: x.title,
                year: parseInt(x.year),
            }))

            const res = await addDeny({
                variables: {
                    input,
                },
            })

            if (res?.data?.addToBlacklist === 'SUCCESS') {
                tableRef.current?.refresh()
                filtersRef.current?.refresh()
            }
        } catch (err) {
            console.log('handleDelete', err)
        }
    }

    const handleRowDoubleClick = (row, e) => {
        if (e.ctrlKey || e.metaKey) {
            const tab = window.open(`/unrecognized/${row.id}`)
            tab.focus()
            return
        }
        navigate(`/unrecognized/${row.id}`)
    }

    return (
        <div className='unrecognized'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ t('unrecognized.title') }</h1>
                    {
                        selectedRows.length > 0 ?
                        <>
                            <Button
                                label={ t('views.delete') }
                                onClick={handleDelete}
                                icon={<DeleteIcon />}
                            />
                            <Button
                                label={ t('views.deny') }
                                onClick={handleAddDeny}
                                icon={<DeleteIcon />}
                            />
                        </>
                        :
                        <></>
                    }
                </div>
                <UnrecognizedFilters
                    ref={filtersRef}
                    onFilterChange={handleFilterChange}
                />
                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={GetUnrecognized}
                        fieldConditions={fieldConditions}
                        queryKey={'getUnrecognized'}
                        hideInputs={true}
                        initialOrderBy={'title'}
                        initialSort={'ASC'}
                        onRowClick={handleRowClick}
                        onRowDoubleClick={handleRowDoubleClick}
                        includeFields={includeFields}
                        extraFilters={extraFilters}
                        useExtraFilters={true}
                        activeRows={selectedRows}
                        allowSelect={true}
                    />
                </div>
            </div>
            <CreateViewsModal
                show={showAddModal}
                close={() => setShowAddModal(false)}
                onSuccess={handleSuccess}
            />
        </div>
    )
}

export default UnrecognizedScreen