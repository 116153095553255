const padWithZero = (num, places) => String(num).padStart(places, '0')

const capitalizeName = (name) => {
    if (!name) return ''
    return name.toLowerCase().replace(/(?:^|\s|['`‘’.-])[^\x00-\x60^\x7B-\xDF](?!(\s|$))/g, (a) => a.toUpperCase())
}

export {
    padWithZero,
    capitalizeName,
}