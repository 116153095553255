import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { GetCreations } from '../../graphql/queries'
import './style.scss'
import { ImportCreations } from '../../graphql/mutations'
import FilterTable from '../../components/FilterTable'
import CreationFilters from '../../components/CreationFilters'
import AddCreationModal from '../../components/AddCreationModal'
import Button from '../../components/Button'
import PlusIcon from '../../components/icons/plus'
import { formatDate } from '../../util/format'
import { capitalizeName } from '../../util/string'
import ImportIcon from '../../components/icons/import'
import ImportCreationsModal from '../../components/ImportCreationsModal'
import Tasks from '../../components/Tasks'
import { TaskTypes } from '../../util/const'

const includeFields = [
    {
        value: 'title',
        label: 'Pealkiri',
    },
    {
        value: 'originalTitle',
        label: 'Orig. pealkiri',
    },
    {
        value: 'year',
        label: 'Aasta',
    },
    {
        value: 'season',
        label: 'Hooaeg',
    },
    {
        value: 'episode',
        label: 'Seeria nr',
    },
    {
        value: 'creationType',
        label: 'Liik',
    },
    {
        value: 'country',
        label: 'Riik',
    },
    {
        value: 'createdAt',
        label: 'Lisatud',
    },
]

const CreationsScreen = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const tableRef = useRef()

    const [extraFilters, setExtraFilters] = useState()
    const [showAddModal, setShowAddModal] = useState(false)
    const [showImportModal, setShowImportModal] = useState(false)

    const handleImportSuccess = async () => {
        tableRef.current?.refresh()
        setShowImportModal(false)
    }

    const handleImportFail = async () => {
        tableRef.current?.refresh()
    }

    const fieldConditions = (row, field) => {
        if (field === 'creationType' && row[field]?.name) return t(row[field].name)
        if (field === 'createdAt') return formatDate(row[field])
        if (['originalTitle', 'title'].includes(field)) return capitalizeName(row[field])
        return row[field]
    }

    const handleRowClick = (row) => {
        navigate(`/creation/${row.id}`)
    }

    const handleFilterChange = async (filters) => {
        setExtraFilters(filters)
    }

    const handleSuccess = (creationId) => {
        setShowAddModal(false)
        navigate(`/creation/${creationId}`)
    }

    return (
        <div className='creations'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ t('creations.title') }</h1>
                    <Button
                        label={ t('creations.add_new') }
                        onClick={() => setShowAddModal(true)}
                        icon={<PlusIcon />}
                    />
                    <Button
                        label={ t('creations.import') }
                        onClick={() => setShowImportModal(true)}
                        icon={<ImportIcon />}
                    />
                </div>
                <Tasks
                    type={TaskTypes.CreationImport}
                />
                <CreationFilters
                    onFilterChange={handleFilterChange}
                />
                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={GetCreations}
                        fieldConditions={fieldConditions}
                        queryKey={'getCreations'}
                        hideInputs={true}
                        includeFields={includeFields}
                        initialOrderBy={'title'}
                        initialSort={'ASC'}
                        onRowClick={handleRowClick}
                        extraFilters={extraFilters}
                        useExtraFilters={true}
                    />
                </div>
            </div>
            <AddCreationModal
                show={showAddModal}
                close={() => setShowAddModal(false)}
                onSuccess={handleSuccess}
            />
            <ImportCreationsModal
                show={showImportModal}
                close={() => setShowImportModal(false)}
                onSuccess={handleImportSuccess}
                onFail={handleImportFail}
            />
        </div>
    )
}

export default CreationsScreen