import React from 'react'
import ActivityIndicator from './ActivityIndicator'

const LoadingComponent = () => {

    return (
        <div className='loading-view'>
            <ActivityIndicator />
        </div>
    )
}

export default LoadingComponent