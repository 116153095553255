import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { GetUsers } from '../../graphql/queries'
import './style.scss'
import UserFilters from '../../components/UserFilters'
import FilterTable from '../../components/FilterTable'
import Button from '../../components/Button'
import AddUserModal from '../../components/AddUserModal'
import PlusIcon from '../../components/icons/plus'
import ImportIcon from '../../components/icons/import'
import ImportUsersModal from '../../components/ImportUsersModal'
import MailIcon from '../../components/icons/mail'
import { TaskTypes } from '../../util/const'
import Tasks from '../../components/Tasks'
import SendMailModal from '../../components/SendMailModal'

const includeFields = [
    {
        value: 'name',
        label: 'Nimi',
    },
    {
        value: 'pid',
        label: 'Isikukood',
    },
    {
        value: 'email',
        label: 'Email',
    },
    {
        value: 'phone',
        label: 'Telefon',
    },
    {
        value: 'ipi',
        label: 'IPI kood',
    },
    {
        value: 'role',
        label: 'Roll',
    },
]

const UsersScreen = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const tableRef = useRef()
    const [showAddModal, setShowAddModal] = useState(false)
    const [extraFilters, setExtraFilters] = useState()
    const [showImportModal, setShowImportModal] = useState(false)
    const [showMailModal, setShowMailModal] = useState(false)

    const handleRowClick = (row) => {
        navigate(`/user/${row.id}`)
    }

    const handleImportSuccess = () => {
        setShowImportModal(false)
        tableRef.current?.refresh()
    }

    const handleFilterChange = async (filters) => {
        setExtraFilters(filters)
    }

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'role':
                return t(row[field])
        }
    }

    const handleAddSuccess = (userId) => {
        setShowAddModal(false)
        navigate(`/user/${userId}`)
    }

    return (
        <div className='users'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ t('users.title') }</h1>
                    <Button
                        label={ t('users.add_new') }
                        onClick={() => setShowAddModal(true)}
                        icon={<PlusIcon />}
                    />
                    <Button
                        label={ t('users.import') }
                        onClick={() => setShowImportModal(true)}
                        icon={<ImportIcon />}
                    />
                    <Button
                        className={'btn-mail'}
                        label={ t('users.send_mail') }
                        onClick={() => setShowMailModal(true)}
                        icon={<MailIcon />}
                    />
                </div>
                <Tasks
                    type={TaskTypes.UserImport}
                />
                <UserFilters
                    onFilterChange={handleFilterChange}
                />
                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={GetUsers}
                        queryKey={'getUsers'}
                        hideInputs={true}
                        includeFields={includeFields}
                        fieldConditions={fieldConditions}
                        initialOrderBy={'name'}
                        onRowClick={handleRowClick}
                        extraFilters={extraFilters}
                        useExtraFilters={true}
                        initialSort={'ASC'}
                    />
                </div>
            </div>
            <ImportUsersModal
                show={showImportModal}
                close={() => setShowImportModal(false)}
                onSuccess={handleImportSuccess}
            />
            <AddUserModal
                show={showAddModal}
                close={() => setShowAddModal(false)}
                onSuccess={handleAddSuccess}
            />
            <SendMailModal
                show={showMailModal}
                close={() => setShowMailModal(false)}
            />
        </div>
    )
}

export default UsersScreen