import React from 'react'
import Creatable from 'react-select/creatable'
import AsyncCreatable from 'react-select/async-creatable'

const CreatableInput = ({
  label,
  value,
  options,
  onInputChange,
  onChange,
  error,
  filterOption,
  getOptionLabel,
  getOptionValue,
  loadingMessage,
  noOptionsMessage,
  defaultInputValue,
  defaultValue,
  load,
  placeholder,
  isClearable,
}) => {

  return (
    <div className={`input-wrapper input-wrapper--creatable${error ? ' input-wrapper--error' : ''}`}>
      <label>
        { label && <span>{ label }</span> }
        {
          load ?
          <AsyncCreatable
            loadOptions={load}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            loadingMessage={loadingMessage}
            noOptionsMessage={noOptionsMessage}
            value={value}
            onChange={onChange}
            defaultInputValue={defaultInputValue}
            defaultValue={defaultValue}
            onInputChange={onInputChange}
            placeholder={placeholder}
            formatCreateLabel={(input) => `Sisesta: ${input}`}
            isClearable={isClearable}
          />
          :
          <Creatable
            options={options}
            onChange={onChange}
            value={value}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            loadingMessage={loadingMessage}
            noOptionsMessage={noOptionsMessage}
            filterOption={filterOption}
            onInputChange={onInputChange}
            placeholder={placeholder}
            formatCreateLabel={(input) => `Sisesta: ${input}`}
            isClearable={isClearable}
          />
        }
      </label>
      { error &&
        <div className="input-error">{ error }</div>
      }
    </div>
  )
}

export default CreatableInput