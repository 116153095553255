import React, { useState } from 'react'
import { EditBudget, EditPoints, RemovePoints } from '../graphql/mutations'
import { useMutation } from '@apollo/client'
import Button from './Button'
import { useTranslation } from 'react-i18next'
import Input from './Input'
import Checkbox from './Checkbox'
import ChevronIcon from './icons/chevron'

const BudgetItem = ({
    data,
    onUpdate,
}) => {

    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [item, setItem] = useState(data)
    const [editBudget] = useMutation(EditBudget)

    const updateBudget = async (field, value) => {
        try {
            await editBudget({
                variables: {
                    id: parseInt(item.id),
                    data: {
                        [field]: value,
                    },
                },
            })

            if (onUpdate) onUpdate()
        } catch (err) {
            console.log('updateBudget: ', err)
        }
    }

    const setField = (field, value) => {
        setItem({
            ...item,
            [field]: value,
        })

        let finalValue = value

        if (field === 'tvBudget' || field === 'vodBudget') finalValue = parseFloat(value)

        updateBudget(field, finalValue)
    }

    return (
        <div className={`budget-items--item${open ? ' budget-items--item-open' : ''}`}>
            <h4 onClick={() => setOpen(!open)}>
                <span>{ item.year }</span>
                <ChevronIcon />
            </h4>
            <div className={`budget-items--item-content`}>
                <Input
                    label={ t('budget.tv_budget') }
                    value={item.tvBudget}
                    onChange={(e) => setField('tvBudget', e.target.value)}
                />
                <Input
                    label={ t('budget.vod_budget') }
                    value={item.vodBudget}
                    onChange={(e) => setField('vodBudget', e.target.value)}
                />
                <Checkbox
                    label={`${t('budget.payed')}`}
                    value={item.payed}
                    onChange={(e) => setField('payed', e.target.checked)}
                />
            </div>
        </div>
    )
}

export default BudgetItem