import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from './Button'
import Input from './Input'
import { useAuth } from '../providers/Auth'
import { useQuery, useMutation } from '@apollo/client'
import { GetMIDStatus, GetSIDStatus } from '../graphql/queries'
import { LoginMID } from '../graphql/mutations'

const MobiilID = ({
    onCancel,
}) => {

    const { t } = useTranslation()
    const { user, signIn } = useAuth()
    const navigate = useNavigate()
    const [pid, setPid] = useState('')
    const [phone, setPhone] = useState('')
    const [area, setArea] = useState('+372')
    const [loginWithMid] = useMutation(LoginMID, {
        fetchPolicy: 'no-cache',
    })
    const [verificationCode, setVerificationCode] = useState()
    const [retries, setRetries] = useState(0)
    const poll = useRef()
    const [error, setError] = useState()
    
    const { refetch: getMIDStatus } = useQuery(GetMIDStatus, {
        skip: true,
        fetchPolicy: 'no-cache',
    })

    useEffect(() => {
        if (user) {
            navigate('/', {
                replace: true,
            })
        }

        return () => {
            reset()
        }
    }, [])

    const reset = () => {
        setError(null)
        setRetries(0)
        poll.current = null
        setVerificationCode(null)
    }

    const login = async () => {
        const { data } = await loginWithMid({
            variables: {
                pid,
                phone: `${area}${phone}`,
            },
        })

        if (data?.loginWithMobiilId?.error) {
            setError(t('login.error'))
            return
        }

        if (data?.loginWithMobiilId?.code) {
            setVerificationCode(data.loginWithMobiilId.code)
            startPoll()
        }
    }

    const startPoll = async () => {
        poll.current = setInterval(async () => {
            if (retries > 10) {
                clearInterval(poll.current)
                return
            }

            try {
                const { data } = await getMIDStatus({
                    pid,
                })
    
                if (!['SUCCESS', 'PENDING'].includes(data.getMIDStatus.status)) {
                    clearInterval(poll.current)
                    setError(t('login.error'))
                    return
                }
    
                if (data.getMIDStatus?.token) {
                    clearInterval(poll.current)
                    signIn(data.getMIDStatus.token)
                    return
                }
            } catch (err) {
                clearInterval(poll.current)
                setError(t('login.error'))
            }

            setRetries(retries + 1)
        }, 3000)
    }

    const handleCancel = () => {
        if (onCancel) onCancel()
    }

    const handleEnter = ({ key }) => {
        if (key === 'Enter') login()
    }

    return (
        <div className='login-method login-method--smartid'>
            <div className='login-method--logo'>
                <img src='./assets/logos/mobiil-id.webp' />
            </div>
            {
                !error ?
                <>
                    {
                        verificationCode ?
                        <div>
                            <div className='verification-code'>
                                <p>{ t('login.verification_code')}</p>
                                <span>{ verificationCode }</span>
                            </div>
                        </div>
                        :
                        <div className='login-method--input'>
                            <div className='login-method--input-phone'>
                                <label>{ t('login.phone') }</label>
                                <div>
                                    <Input
                                        value={area}
                                        onChange={(e) => setArea(e.target.value)}
                                        placeholder={'+372'}
                                    />
                                    <Input
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        placeholder={t('login.enter_phone')}
                                    />
                                </div>
                            </div>
                            <Input
                                label={t('login.personal_number')}
                                value={pid}
                                onChange={(e) => setPid(e.target.value)}
                                onKeyUp={handleEnter}
                                placeholder={t('login.enter_personal_number')}
                            />
                            <div className='login-actions'>
                                <Button
                                    className={'btn-cancel'}
                                    label={t('login.cancel')}
                                    onClick={handleCancel}
                                />
                                <Button
                                    label={t('login.continue')}
                                    onClick={login}
                                    disabled={!pid || pid.length < 11}
                                />
                            </div>
                        </div>
                    }
                </>
                :
                <div className='login-method--error'>
                    <div className='login-method--error-message'>
                        <p>{ error }</p>
                    </div>
                    <div className='login-actions'>
                        <Button
                            className={'btn-cancel'}
                            label={t('login.back')}
                            onClick={reset}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default MobiilID