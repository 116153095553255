import React, { useState } from 'react'
import Button from './Button'
import { useTranslation } from 'react-i18next'
import Input from './Input'

const AuthorItem = ({
    data,
}) => {

    const { t } = useTranslation()
    const [open, setOpen] = useState(false)

    return (
        <div className={`profile-authors--item${open ? ' user-authors--item-open' : ''}`}>
            <h4 onClick={() => setOpen(!open)}>
                <span>{ data.author.name }</span>
            </h4>
            <div className={`profile-authors--item-content`}>
                <Input
                    disabled={true}
                    className={'profile-authors--item-percentage'}
                    label={ t('user.heir_percentage') }
                    value={data.percentage}
                    suffix={'%'}
                    type={'number'}
                />
            </div>
        </div>
    )
}

export default AuthorItem