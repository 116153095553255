import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import Button from './Button'
import Modal from './Modal'
import DownloadIcon from './icons/download'
import { GetReportExcel } from '../graphql/queries'
import { formatDate } from '../util/format'
import ExportReportFilters from './ExportReportFilters'
import ErrorMessage from './ErrorMessage'
import SuccessMessage from './SuccessMessage'

const initialErrorsState = {
    generic: null,
}

const ExportModal = ({
    show,
    close,
    onSuccess,
}) => {

    const { t } = useTranslation()

    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState(initialErrorsState)
    const [success, setSuccess] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [link, setLink] = useState(null)
    const [filters, setFilters] = useState()

    const { refetch } = useQuery(GetReportExcel, {
        skip: true,
        fetchPolicy: 'no-cache',
    })

    const handleExport = async () => {
        setLoading(true)
        setErrors(initialErrorsState)
        setSuccess(null)
        try {
            const res = await refetch({
                year: parseInt(filters.year),
                type: filters.type,
                distribution: filters.distribution,
                society: filters.society,
                foreign: filters.foreign,
                bonus: filters.bonus,
            })

            if (!res?.data?.getReportExcel || res.data.getReportExcel === 'FAIL') {
                setErrors({
                    ...errors,
                    generic: t('reports.excel_error'),
                })
                return
            }

            setLink(`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data.getReportExcel}`)
            setFileName(
                `${filters.year} ${filters.type}${filters.distribution ? ` ${filters.distribution}` : ''}${filters.society ? ` ${filters.society}` : ''}${filters.foreign ? ` Välisjaotus` : ''} ${formatDate((new Date()).toISOString())}.xlsx`
            )

            setSuccess(t('reports.excel_success'))
        } catch (err) {
            setErrors({
                ...errors,
                generic: t('reports.excel_error'),
            })
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const handleClose = () => {
        if (close) close()
        setErrors(initialErrorsState)
        setLink(null)
        setFileName(null)
        setFilters(null)
        setSuccess(null)
    }

    const handleFilterChange = async (filters) => {
        setLink(null)
        setFileName(null)
        setSuccess(null)
        setErrors(initialErrorsState)
        setFilters(filters)
    }

    return (
        <Modal
            className={'modal-export'}
            show={show}
            close={handleClose}
            title={ t('reports.export') }
        >
            <h4>{ t('reports.filters') }</h4>
            <ExportReportFilters
                onFilterChange={handleFilterChange}
                allowed={{
                    type: true,
                    year: true,
                    distribution: true,
                    society: false,
                    foreign: true,
                    bonus: true,
                }}
            />
            <ErrorMessage message={errors?.generic} />
            <SuccessMessage message={success} />
            
            <div className='modal-export--actions'>
                <Button
                    label={ t('reports.download_excel') }
                    link={link}
                    download={fileName}
                    disabled={!link || !fileName}
                    icon={<DownloadIcon />}
                />
                <Button
                    label={ t('reports.start_export') }
                    onClick={handleExport}
                    loading={loading}
                />
            </div>
        </Modal>
    )
}

export default ExportModal