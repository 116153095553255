import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { GetProfile } from '../../graphql/queries'
import './style.scss'
import { useAuth } from '../../providers/Auth'
import UserReportsScreen from '../UserReports'
import UserBonusesScreen from '../UserBonuses'
import UserForeignScreen from '../UserForeign'

const tabs = [
    {
        label: 'user_reports.reports',
        type: 'report',
    },
    {
        label: 'user_reports.foreign',
        type: 'foreign',
    },
    {
        label: 'user_reports.bonuses',
        type: 'bonus',
    },
]

const UserTabsScreen = () => {

    const { t } = useTranslation()
    const { role, authorId } = useParams()
    const { user } = useAuth()
    const [currentAuthor, setCurrentAuthor] = useState(null)
    const [currentTab, setCurrentTab] = useState('report')

    useQuery(GetProfile, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (!data?.getProfile?.authors) return
            const heir = data.getProfile.authors.find(x => x.author.id === parseInt(authorId))
            if (heir) setCurrentAuthor(heir)
        },
    })

    const renderContent = () => {
        switch (currentTab) {
            case 'report':
                return (
                    <UserReportsScreen
                        role={role}
                        authorId={authorId}
                    />
                )
            case 'bonus':
                return (
                    <UserBonusesScreen
                        role={role}
                        authorId={authorId}
                    />
                )
            case 'foreign':
                return (
                    <UserForeignScreen
                        role={role}
                        authorId={authorId}
                    />
                )
            default:
                return (
                    <UserReportsScreen
                        role={role}
                        authorId={authorId}
                    />
                )
        }
    }

    const renderTab = (label, type) => {
        return (
            <div
                key={type}
                className={`user-tabs--item${currentTab === type ? ' user-tabs--item-active' : ''}`}
                onClick={() => setCurrentTab(type)}
            >
                <span>{ t(label) }</span>
            </div>
        )
    }

    if (role !== 'author' && !authorId) return <></>

    return (
        <div className='user-tabs'>
            <div className='inner'>
                <div className='inner-title'>
                    {
                        role === 'author' ?
                        <h1>{ user.firstName }</h1>
                        :
                        <h1>{ currentAuthor?.author?.name }</h1>
                    }
                </div>
                <div className='user-tabs--items'>
                    {
                        tabs.map(x => renderTab(x.label, x.type))
                    }
                </div>

                <div className='user-tabs--content'>
                    { renderContent() }
                </div>
            </div>
        </div>
    )
}

export default UserTabsScreen