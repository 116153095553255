import { useMutation } from '@apollo/client'
import jwtDecode from 'jwt-decode'
import React, { useState, useEffect, useContext, createContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Login, LoginSID, ValidateToken } from '../graphql/mutations'

const authContext = createContext()

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth()

  return <authContext.Provider
            value={ auth }
        >
            { children }
        </authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
}

const useProvideAuth = () => {
  const [user, setUser] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [login] = useMutation(Login)
  const [loginSID] = useMutation(LoginSID)
  const [validateToken] = useMutation(ValidateToken)
  const navigate = useNavigate()

  useEffect(() => {
    validate()
    // eslint-disable-next-line
  }, [])

  const signIn = (token) => {
    localStorage.setItem('token', token)

    const userData = jwtDecode(token)

    setUser(userData)
    setLoggedIn(true)

    navigate('/')
  }

  const validate = async () => {
    const token = localStorage.getItem('token')
    const role = localStorage.getItem('role')

    if (!token) {
      navigate('/login')
      setLoaded(true)
      return
    }

    try {
      const { data } = await validateToken({
        variables: {
          token,
        },
      })

      if (data?.validateToken) {
        const userData = jwtDecode(token)

        setUser({
          ...userData,
          selectedRole: role,
        })
        setLoggedIn(true)
        return
      }
    } catch (err) {
      console.log('Error validating token', err)
      signOut()
    } finally {
      setLoaded(true)
    }
  }

  const signOut = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('role')
    setUser(null)
    setLoggedIn(false)
    navigate('/login')
  }

  const selectRole = (role) => {
    localStorage.setItem('role', role)
    setUser({
      ...user,
      selectedRole: role,
    })
  }

  return {
    user,
    loggedIn,
    signIn,
    signOut,
    loaded,
    selectRole,
  }
}