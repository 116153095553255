import React, { useState } from 'react'
import { EditPoints, RemovePoints } from '../graphql/mutations'
import { useMutation } from '@apollo/client'
import Button from './Button'
import { useTranslation } from 'react-i18next'
import Input from './Input'

const PointItem = ({
    data,
    types,
    onRemove,
    onUpdate,
}) => {

    const { t } = useTranslation()
    const [item, setItem] = useState(data)
    const [deletePoints] = useMutation(RemovePoints)
    const [editPoints] = useMutation(EditPoints)

    const removePoints = async () => {
        try {
            await deletePoints({
                variables: {
                    id: parseInt(item.id),
                },
            })

            if (onRemove) onRemove()
        } catch (err) {
            console.log('removePoints: ', err)
        }
    }

    const updatePoints = async (points) => {
        try {
            await editPoints({
                variables: {
                    id: parseInt(item.id),
                    data: {
                        points: parseInt(points),
                    },
                },
            })

            if (onUpdate) onUpdate()
        } catch (err) {
            console.log('updatePoints: ', err)
        }
    }

    return (
        <tr
            className='settings-points--item'
        >
            <td>
                <Input
                    value={item.points ? item.points : ''}
                    onChange={(e) => {
                        setItem({
                            ...item,
                            points: e.target.value,
                        })
                        updatePoints(e.target.value)
                    }}
                />
            </td>
            <td>
                { item.start ? `${item.start}${item.type === 'VOD' ? 'x' : 'min'}` : '' }
            </td>
            <td>
                { item.end ? `${item.end}${item.type === 'VOD' ? 'x' : 'min'}` : '' }
            </td>
            <td>
                { types.some(x => x.id === item.category) ? t(types.find(x => x.id === item.category).name) : '' }
            </td>
            <td>
                <Button
                    className={'btn-small'}
                    label={t('settings.points_remove')}
                    onClick={() => removePoints(item.id)}
                />
            </td>
        </tr>
    )
}

export default PointItem