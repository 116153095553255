import React, { useRef, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { GetBonuses, GetBonusTotal } from '../../graphql/queries'
import './style.scss'
import FilterTable from '../../components/FilterTable'
import Button from '../../components/Button'
import BonusFilters from '../../components/BonusFilters'
import PlusIcon from '../../components/icons/plus'
import ImportIcon from '../../components/icons/import'
import ImportBonusModal from '../../components/ImportBonusModal'
import AddBonusModal from '../../components/AddBonusModal'
import { formatDate } from '../../util/format'
import DeleteIcon from '../../components/icons/delete'
import ExportIcon from '../../components/icons/excel'
import { RemoveBonuses } from '../../graphql/mutations'
import Tasks from '../../components/Tasks'
import { TaskTypes } from '../../util/const'
import ExportBonusModal from '../../components/ExportBonusModal'
import Total from '../../components/Total'

const includeFields = [
    {
        value: 'name',
        label: 'Nimi',
    },
    {
        value: 'pid',
        label: 'Isikukood',
    },
    {
        value: 'label',
        label: 'Nimetus',
    },
    {
        value: 'sum',
        label: 'Boonus',
    },
    {
        value: 'description',
        label: 'Selgitus',
    },
    {
        value: 'date',
        label: 'Kuupäev',
    },
]

const BonusesScreen = () => {

    const { t } = useTranslation()
    const tableRef = useRef()
    const filtersRef = useRef()

    const [extraFilters, setExtraFilters] = useState()
    const [showAddModal, setShowAddModal] = useState(false)
    const [exportModal, setExportModal] = useState(false)
    const [showImportModal, setShowImportModal] = useState(false)
    const [removeBonuses] = useMutation(RemoveBonuses)
    const [selectedRows, setSelectedRows] = useState([])
    const [total, setTotal] = useState(null)

    useQuery(GetBonusTotal, {
        variables: {
            year: extraFilters?.year,
            query: extraFilters?.query,
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            setTotal(data?.getBonusTotal)
        }
    })

    const handleRowClick = (selection) => {
        if (selectedRows.length === 1 && selectedRows[0] === selection) {
            setSelectedRows([])
            return
        }
        if (Array.isArray(selection)) {
            setSelectedRows(selection)
        } else {
            setSelectedRows([selection])
        }
    }

    const fieldConditions = (row, field) => {
        if (field === 'date') return formatDate(row[field])
        if (field === 'sum') return `${row[field]} €`
        return row[field]
    }

    const handleSuccess = () => {
        setShowAddModal(false)
        tableRef.current?.refresh()
        filtersRef.current?.refresh()
    }

    const handleFilterChange = async (filters) => {
        setExtraFilters(filters)
    }

    const handleImportSuccess = () => {
        setShowImportModal(false)
        tableRef.current?.refresh()
    }

    const handleDelete = async () => {
        try {
            const res = await removeBonuses({
                variables: {
                    ids: selectedRows.map(x => x.id),
                },
            })

            if (res?.data?.removeBonuses === 'SUCCESS') {
                tableRef.current?.refresh()
            }

            console.log('Delete bonuses:', res)
        } catch (err) {
            console.log('handleDelete', err)
        }
    }

    return (
        <div className='bonuses'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ t('bonuses.title') }</h1>
                    <Button
                        label={ t('bonuses.add_new') }
                        onClick={() => setShowAddModal(true)}
                        icon={<PlusIcon />}
                    />
                    <Button
                        label={ t('bonuses.import') }
                        onClick={() => setShowImportModal(true)}
                        icon={<ImportIcon />}
                    />
                    <Button
                        label={ t('reports.export') }
                        onClick={() => setExportModal(true)}
                        icon={<ExportIcon />}
                    />
                    {
                        selectedRows.length > 0 ?
                        <Button
                            label={ t('bonuses.delete') }
                            onClick={handleDelete}
                            icon={<DeleteIcon />}
                        />
                        :
                        <></>
                    }
                </div>

                <Tasks
                    type={TaskTypes.BonusImport}
                />

                <div className='below-title'>
                    <BonusFilters
                        ref={filtersRef}
                        onFilterChange={handleFilterChange}
                        allowed={{
                            search: true,
                            year: true,
                        }}
                    />
                    <Total
                        total={total}
                        hidePayed={true}
                    />
                </div>

                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={GetBonuses}
                        queryKey={'getBonuses'}
                        hideInputs={true}
                        fieldConditions={fieldConditions}
                        includeFields={includeFields}
                        initialOrderBy={'date'}
                        onRowClick={handleRowClick}
                        initialSort={'ASC'}
                        extraFilters={extraFilters}
                        useExtraFilters={true}
                        activeRows={selectedRows}
                        allowSelect={true}
                    />
                </div>
            </div>
            <AddBonusModal
                show={showAddModal}
                close={() => setShowAddModal(false)}
                onSuccess={handleSuccess}
            />
            <ImportBonusModal
                show={showImportModal}
                close={() => setShowImportModal(false)}
                onSuccess={handleImportSuccess}
            />
            <ExportBonusModal
                show={exportModal}
                close={() => setExportModal(false)}
            />
        </div>
    )
}

export default BonusesScreen