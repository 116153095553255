import React from 'react'

const MailSentIcon = ({ fill }) => {

    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
            <circle style={{
                fill: '#263A7A',
            }} cx="256" cy="256" r="256"/>
            <path style={{
                fill: '#121149',
            }} d="M396.062,148.928l-277.782,186.93l173.502,173.64c121.637-17.015,215.866-119.491,220.053-244.796
                L396.062,148.928z"/>
            <path style={{
                fill: '#FFEDB5',
            }} d="M131.489,142.222h249.02c11.867,0,21.488,9.621,21.488,21.488v155.198
                c0,11.867-9.621,21.488-21.488,21.488h-20.225v47.107c0,9.206-11.13,13.815-17.637,7.306l-54.413-54.413H131.491
                c-11.867,0-21.488-9.621-21.488-21.488V163.711C110.001,151.843,119.622,142.222,131.489,142.222z"/>
            <g>
                <path style={{
                    fill: '#FEE187',
                }} d="M401.998,163.711c0-11.867-9.621-21.488-21.488-21.488H255.426v198.175h32.808l54.413,54.413
                    c6.509,6.509,17.637,1.9,17.637-7.306v-47.107h20.225c11.867,0,21.488-9.621,21.488-21.488V163.711z"/>
                <path style={{
                    fill: '#FEE187',
                }} d="M342.647,394.811c6.509,6.509,17.637,1.9,17.637-7.306v-47.107h20.225
                    c10.692,0,19.535-7.818,21.187-18.044L256,227.556l-145.698,94.798c1.652,10.226,10.495,18.044,21.187,18.044h156.743
                    L342.647,394.811z"/>
            </g>
            <path style={{
                fill: '#FFC91B',
            }} d="M256,227.556l-0.574,0.374v112.468h32.808l54.413,54.413c6.509,6.509,17.637,1.9,17.637-7.306
                v-47.107h20.225c10.692,0,19.535-7.818,21.187-18.044L256,227.556z"/>
            <path style={{
                fill: '#FFC61B',
            }} d="M116.891,147.973L256,256l139.107-108.027c-3.834-3.558-8.954-5.751-14.598-5.751h-249.02
                C125.847,142.222,120.727,144.415,116.891,147.973z"/>
            <path style={{
                fill: '#D48B07',
            }} d="M395.107,147.973c-3.834-3.558-8.954-5.751-14.598-5.751H255.426v113.331L256,256L395.107,147.973z"
                />
        </svg>
    )
}

export default MailSentIcon