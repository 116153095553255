import React, { useEffect, useState } from 'react'
import { secondsToHms } from '../util/time'

const initialState = {
  h: '',
  m: '',
  s: '',
}

const DurationInput = ({
  label,
  onChange,
  onFocus,
  onBlur,
  className,
  initialValue,
  error,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const [values, setValues] = useState(initialState)

  useEffect(() => {
    if (initialValue) {
      setValues(secondsToHms(initialValue))
    }
    return () => {
      setIsFocused(false)
      setValues(initialState)
    }
  }, [])

  const handleFocus = () => {
    setIsFocused(true)
    if (onFocus) onFocus()
  }
  const handleBlur = () => {
    setIsFocused(false)
    if (onBlur) onBlur()
  }

  const handleChange = ({
    h,
    m,
    s,
  }) => {
    const data = values

    if (h !== values.h) data.h = h
    if (m !== values.m) data.m = m
    if (s !== values.s) data.s = s

    setValues({
      ...values,
      ...data,
    })

    const hoursToSeconds = isNaN(h) ? 0 : h * 3600
    const minutesToSeconds = isNaN(m) ? 0 : m * 60
    const seconds = isNaN(s) ? 0 : s * 1

    if (onChange) onChange(hoursToSeconds + minutesToSeconds + seconds)
  }

  return (
    <div className={`input-wrapper input-wrapper--duration${className ? ' ' + className : ''}${error ? ' input-wrapper--error' : ''}`}>
      <label>
        { label && <span>{ label }</span> }
        <div className={`input-wrapper--control${isFocused ? ' input-wrapper--control-focused' : ''}`}>
          <div className='input-wrapper--input'>
            <input
              type={'number'}
              placeholder={'hh'}
              onChange={(e) => handleChange({ ...values, h: !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : '' })}
              value={values.h}
              maxLength={2}
              onFocus={handleFocus}
              onBlur={handleBlur}
              min={0}
            />
          </div>
          <div className='input-wrapper--suffix'>tundi</div>
          <div className='input-wrapper--input'>
            <input
              type={'number'}
              placeholder={'mm'}
              onChange={(e) => handleChange({ ...values, m: !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : '' })}
              value={values.m}
              maxLength={2}
              onFocus={handleFocus}
              onBlur={handleBlur}
              min={0}
            />
          </div>
          <div className='input-wrapper--suffix'>minutit</div>
          <div className='input-wrapper--input'>
            <input
              type={'number'}
              placeholder={'ss'}
              onChange={(e) => handleChange({ ...values, s: !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : '' })}
              value={values.s}
              maxLength={2}
              onFocus={handleFocus}
              onBlur={handleBlur}
              min={0}
            />
          </div>
          <div className='input-wrapper--suffix'>sekundit</div>
        </div>
      </label>
      { error &&
        <div className="input-error">{ error }</div>
      }
    </div>
  )
}

export default DurationInput