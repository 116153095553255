import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../providers/Auth'
import SelectInput from '../../components/SelectInput'
import Checkbox from '../../components/Checkbox'
import { useMutation, useQuery } from '@apollo/client'
import { GetAuthorRoles, GetBudgets, GetCreation, GetCreations, GetCreationTypes, GetPoints, GetUsers, SearchUsers } from '../../graphql/queries'
import './style.scss'
import ActivityIndicator from '../../components/ActivityIndicator'
import Input from '../../components/Input'
import { AddBudget, AddCreationAuthor, AddPoints, EditCreation, RemoveCreationAuthor, RemovePoints } from '../../graphql/mutations'
import { secondsToHms } from '../../util/time'
import { padWithZero } from '../../util/string'
import Button from '../../components/Button'
import PointItem from '../../components/PointItem'
import BudgetItem from '../../components/BudgetItem'
import PlusIcon from '../../components/icons/plus'

const budgetState = {
    year: null,
    tvBudget: null,
    vodBudget: null,
    payed: false,
}

const BudgetScreen = () => {

    const { t } = useTranslation()
    const [createBudget] = useMutation(AddBudget)
    const [item, setItem] = useState(budgetState)
    const [budgets, setBudgets] = useState([])
    const [errors, setErrors] = useState({
        ...budgetState,
    })

    const { data, refetch, loading } = useQuery(GetBudgets, {
        onCompleted: (data) => {
            if (!data?.getBudgets) return
            setBudgets(data.getBudgets)
        },
    })

    const addBudget = async () => {
        if (validateInput()) return

        try {
            const data = {
                year: parseInt(item.year),
                tvBudget: parseFloat(item.tvBudget),
                vodBudget: parseFloat(item.vodBudget),
                payed: item.payed,
            }

            await createBudget({
                variables: {
                    data,
                },
            })

            setItem(budgetState)
            refetch()
        } catch (err) {
            console.log('addBudget: ', err)
        }
    }

    const validateInput = () => {
        let hasErrors = false
        const errorState = {
            ...budgetState,
        }

        if (!item.year) {
            hasErrors = true
            errorState.year = t('budget.fill_field')
        }

        if (budgets.some(x => x.year === parseInt(item.year))) {
            hasErrors = true
            errorState.year = t('budget.year_exists')
        }

        if (!item.tvBudget) {
            hasErrors = true
            errorState.tvBudget = t('budget.fill_field')
        }
        if (!item.vodBudget) {
            hasErrors = true
            errorState.vodBudget = t('budget.fill_field')
        }

        setErrors({
            ...errorState,
        })
        return hasErrors
    }

    if (loading) return <ActivityIndicator />

    return (
        <div className='budget'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ t('budget.title') }</h1>
                </div>
                <div className='budget-content'>
                    <div className='budget-info'>
                        <h4>{ t('budget.budgets') }</h4>
                        {
                            budgets && budgets.length > 0 ?
                            <div className='budget-items'>
                                {
                                    budgets.map((budget) =>
                                        <BudgetItem
                                            key={budget.id}
                                            data={budget}
                                        />
                                    )
                                }
                            </div>
                            :
                            <></>
                        }
                    </div>
                    <div className='budget-add'>
                        <h4>{ t('budget.add_budget') }</h4>
                        
                        <Input
                            label={t('budget.year')}
                            value={ item.year }
                            onChange={(e) => setItem({ ...item, year: e.target.value })}
                            error={errors.year}
                        />
                        <Input
                            label={t('budget.tv_budget')}
                            value={ item.tvBudget }
                            onChange={(e) => setItem({ ...item, tvBudget: e.target.value })}
                            error={errors.tvBudget}
                        />
                        <Input
                            label={t('budget.vod_budget')}
                            value={ item.vodBudget }
                            onChange={(e) => setItem({ ...item, vodBudget: e.target.value })}
                            error={errors.vodBudget}
                        />
                        <Checkbox
                            label={`${t('budget.payed')}`}
                            value={item.payed}
                            onChange={(e) => setItem({ ...item, payed: e.target.checked })}
                        />

                        <Button
                            label={t('budget.add_budget')}
                            onClick={addBudget}
                            icon={<PlusIcon />}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BudgetScreen