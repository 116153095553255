import gql from 'graphql-tag'

export const Login = gql`
  mutation login ($email: String!, $password: String!) {
    login (email: $email, password: $password) {
      status
      token
    }
  }
`
export const ResetPassword = gql`
  mutation resetPassword ($email: String!) {
    resetPassword (email: $email)
  }
`

export const LoginSID = gql`
  mutation loginWithSmartId ($pid: String!) {
    loginWithSmartId (pid: $pid) {
      code
      error
    }
  }
`

export const LoginMID = gql`
  mutation loginWithMobiilId ($phone: String!, $pid: String!) {
    loginWithMobiilId (phone: $phone, pid: $pid) {
      code
      error
    }
  }
`

export const ValidateIDCard = gql`
  mutation validateIdCard ($input: IdInput!) {
    validateIdCard (input: $input) {
      status
      token
    }
  }
`

export const ValidateToken = gql`
  mutation validateToken ($token: String!) {
    validateToken (token: $token)
  }
`

export const ImportCreations = gql`
  mutation importCreations ($file: Upload!) {
    importCreations (file: $file) {
      title
      year
      episode
      error
      type
    }
  }
`

export const AddCreation = gql`
  mutation addCreation ($data: CreationInput!) {
    addCreation (data: $data)
  }
`

export const RemoveCreation = gql`
  mutation removeCreation ($id: Int!) {
    removeCreation (id: $id)
  }
`

export const EditCreation = gql`
  mutation editCreation ($id: Int!, $data: CreationInput!) {
    editCreation (id: $id, data: $data)
  }
`

export const AddCreationAuthor = gql`
  mutation addCreationAuthor ($data: CreationAuthorInput!) {
    addCreationAuthor (data: $data)
  }
`

export const EditCreationAuthor = gql`
  mutation editCreationAuthor ($id: Int!, $data: CreationAuthorInput!) {
    editCreationAuthor (id: $id, data: $data)
  }
`

export const RemoveCreationAuthor = gql`
  mutation removeCreationAuthor ($id: Int!) {
    removeCreationAuthor (id: $id)
  }
`

export const AddPoints = gql`
  mutation addPoints ($data: PointsInput!) {
    addPoints (data: $data)
  }
`

export const RemovePoints = gql`
  mutation removePoints ($id: Int!) {
    removePoints (id: $id)
  }
`

export const EditPoints = gql`
  mutation editPoints ($id: Int!, $data: PointsInput!) {
    editPoints (id: $id, data: $data)
  }
`

export const AddBudget = gql`
  mutation addBudget ($data: BudgetInput!) {
    addBudget (data: $data)
  }
`

export const EditBudget = gql`
  mutation editBudget ($id: Int!, $data: BudgetInput!) {
    editBudget (id: $id, data: $data)
  }
`

export const AddUser = gql`
  mutation addUser ($data: UserInput!) {
    addUser (data: $data)
  }
`

export const RemoveUser = gql`
  mutation removeUser ($id: Int!) {
    removeUser (id: $id)
  }
`

export const EditUser = gql`
  mutation editUser ($id: Int!, $data: UserInput!) {
    editUser (id: $id, data: $data)
  }
`
export const EditProfile = gql`
  mutation editProfile ($data: UserInput!) {
    editProfile (data: $data)
  }
`

export const AddHeir = gql`
  mutation addHeir ($data: InheritanceInput!) {
    addHeir (data: $data)
  }
`

export const RemoveHeir = gql`
  mutation removeHeir ($id: Int!) {
    removeHeir (id: $id)
  }
`

export const EditHeir = gql`
  mutation editHeir ($id: Int!, $data: InheritanceInput!) {
    editHeir (id: $id, data: $data)
  }
`

export const AddSociety = gql`
  mutation addSociety ($data: SocietyInput!) {
    addSociety (data: $data)
  }
`

export const EditSociety = gql`
  mutation editSociety ($id: Int!, $data: SocietyInput!) {
    editSociety (id: $id, data: $data)
  }
`

export const RemoveSociety = gql`
  mutation removeSociety ($id: Int!) {
    removeSociety (id: $id)
  }
`
export const AddItemToSociety = gql`
  mutation addItemToSociety ($id: Int!, $roleId: Int, $typeId: Int) {
    addItemToSociety (id: $id, roleId: $roleId, typeId: $typeId)
  }
`

export const RemoveItemFromSociety = gql`
  mutation removeItemFromSociety ($roleId: Int, $typeId: Int) {
    removeItemFromSociety (roleId: $roleId, typeId: $typeId)
  }
`

export const ImportSocieties = gql`
  mutation importSocieties ($file: Upload!) {
    importSocieties (file: $file) {
      code
      items {
        name
        code
        country
      }
    }
  }
`

export const ImportViews = gql`
mutation importViews ($file: Upload!, $options: ViewImportInput!) {
  importViews (file: $file, options: $options) {
    failed {
      local {
        distribution
        year
        type
        title
        originalTitle
        season
        episode
        count
        creationId
        country
        producer
      }
      ida {
        distribution
        year
        type
        title
        originalTitle
        season
        episode
        count
        creationId
        country
        producer
      }
      tmdb {
        distribution
        year
        type
        title
        originalTitle
        season
        episode
        count
        creationId
        country
        producer
      }
    }
    matched {
      local {
        distribution
        year
        type
        title
        originalTitle
        season
        episode
        count
        creationId
        country
        producer
      }
      ida {
        distribution
        year
        type
        title
        originalTitle
        season
        episode
        count
        creationId
        country
        producer
      }
      tmdb {
        distribution
        year
        type
        title
        originalTitle
        season
        episode
        count
        creationId
        country
        producer
      }
    }
  }
}
`

export const RemoveViews = gql`
  mutation removeViews ($ids: [Int!]) {
    removeViews (ids: $ids)
  }
`

export const RemoveUnrecognized = gql`
  mutation removeUnrecognized ($ids: [Int!]) {
    removeUnrecognized (ids: $ids)
  }
`

export const CreateReport = gql`
  mutation createReport ($type: String!, $year: Int!) {
    createReport (type: $type, year: $year) {
      message
      reportId
      failed {
        id
        error
        distribution
        country
        year
        type
        count
        creationId
      }
    }
  }
`

export const AddCreationDistribution = gql`
  mutation addCreationDistribution ($creationType: Int!, $role: Int!, $amount: Int!) {
    addCreationDistribution (creationType: $creationType, role: $role, amount: $amount)
  }
`

export const RemoveCreationDistribution = gql`
  mutation removeCreationDistribution ($id: Int!) {
    removeCreationDistribution (id: $id)
  }
`

export const EditCreationDistribution = gql`
  mutation editCreationDistribution ($id: Int!, $data: CreationDistributionInput!) {
    editCreationDistribution (id: $id, data: $data)
  }
`

export const StopTask = gql`
  mutation stopTask ($id: Int!) {
    stopTask (id: $id)
  }
`

export const ImportBonuses = gql`
  mutation importBonuses ($file: Upload!) {
    importBonuses (file: $file) {
      code
      items {
        name
        pid
        sum
      }
    }
  }
`
export const AddBonus = gql`
  mutation addBonus ($data: BonusInput!) {
    addBonus (data: $data)
  }
`

export const RemoveBonuses = gql`
  mutation removeBonuses ($ids: [Int!]) {
    removeBonuses (ids: $ids)
  }
`
export const ExportYearReport = gql`
  mutation exportYearReport ($year: Int!) {
    exportYearReport (year: $year)
  }
`
export const ImportUsers = gql`
  mutation importUsers ($file: Upload!) {
    importUsers (file: $file) {
      code
      items {
        name
        pid
        ipi
        error
      }
    }
  }
`

export const ImportForeign = gql`
  mutation importForeign ($file: Upload!) {
    importForeign (file: $file) {
      code
      items {
        name
        pid
        ipi
        sum
      }
    }
  }
`
export const AddForeign = gql`
  mutation addForeign ($data: ForeignInput!) {
    addForeign (data: $data)
  }
`

export const RemoveForeign = gql`
  mutation removeForeign ($ids: [Int!]) {
    removeForeign (ids: $ids)
  }
`

export const AddToBlacklist = gql`
  mutation addToBlacklist ($input: [BlacklistInput!]) {
    addToBlacklist (input: $input)
  }
`
export const CreationFromUnrecognized = gql`
  mutation creationFromUnrecognized ($id: Int!) {
    creationFromUnrecognized (id: $id)
  }
`

export const AddUnrecognizedAuthor = gql`
  mutation addUnrecognizedAuthor ($data: UnrecognizedAuthorInput!) {
    addUnrecognizedAuthor (data: $data)
  }
`

export const EditUnrecognized = gql`
  mutation editUnrecognized ($id: Int!, $data: UnrecognizedInput!) {
    editUnrecognized (id: $id, data: $data)
  }
`

export const EditUnrecognizedAuthor = gql`
  mutation editUnrecognizedAuthor ($id: Int!, $data: UnrecognizedAuthorInput!) {
    editUnrecognizedAuthor (id: $id, data: $data)
  }
`

export const RemoveUnrecognizedAuthor = gql`
  mutation removeUnrecognizedAuthor ($ids: [Int!]) {
    removeUnrecognizedAuthor (ids: $ids)
  }
`
export const SendEmailToUsers = gql`
  mutation sendEmailToUsers ($subject: String!, $content: String!, $ids: [Int!]) {
    sendEmailToUsers (subject: $subject, content: $content, ids: $ids)
  }
`
