import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import SelectInput from '../../components/SelectInput'
import CreatableInput from '../../components/CreatableInput'
import Checkbox from '../../components/Checkbox'
import { useMutation, useQuery } from '@apollo/client'
import { GetAuthorRoles, GetCreation, GetCreationTypes, SearchUsers } from '../../graphql/queries'
import './style.scss'
import ActivityIndicator from '../../components/ActivityIndicator'
import Input from '../../components/Input'
import { AddCreationAuthor, EditCreation, RemoveCreation, RemoveCreationAuthor } from '../../graphql/mutations'
import { secondsToHms } from '../../util/time'
import { padWithZero, capitalizeName } from '../../util/string'
import Button from '../../components/Button'
import DeleteIcon from '../../components/icons/delete'
import ConfirmModal from '../../components/ConfirmModal'
import PlusIcon from '../../components/icons/plus'
import DurationInput from '../../components/DurationInput'
import Authors from '../../components/Authors'

const initialState = {
    title: null,
    originalTitle: null,
    year: null,
    season: null,
    episode: null,
    duration: null,
    country: null,
    producer: null,
    type: null,
    blacklisted: false,
}

const authorState = {
    authorName: null,
    authorRole: null,
    ipi: null,
}

const CreationScreen = () => {

    const { t } = useTranslation()
    const { id } = useParams()
    const navigate = useNavigate()
    const [editCreation] = useMutation(EditCreation)
    const [addCreationAuthor] = useMutation(AddCreationAuthor)
    const [removeCreation] = useMutation(RemoveCreation)
    const [removeCreationAuthor] = useMutation(RemoveCreationAuthor)
    const [item, setItem] = useState(initialState)
    const [author, setAuthor] = useState(authorState)
    const [types, setTypes] = useState([])
    const [roles, setRoles] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)

    const { refetch: searchUsers } = useQuery(SearchUsers, {
        skip: true,
    })

    const { data, refetch, loading } = useQuery(GetCreation, {
        variables: {
            id: parseInt(id),
        },
        onCompleted: (data) => {
            if (!data?.getCreation) return
            setItem(data.getCreation)
        },
    })

    useQuery(GetCreationTypes, {
        onCompleted: (data) => {
            if (!data?.getCreationTypes) return
            setTypes(data.getCreationTypes)
        },
    })

    useQuery(GetAuthorRoles, {
        onCompleted: (data) => {
            if (!data?.getAuthorRoles) return
            setRoles(data.getAuthorRoles)
        },
    })

    const setField = (field, value) => {
        setItem({
            ...item,
            [field]: value,
        })

        let finalValue = value
        
        if (['year', 'season', 'episode', 'duration'].includes(field)) finalValue = parseInt(value)

        updateProperty(field, finalValue)
    }

    const changeType = async (type) => {
        setItem({
            ...item,
            type,
        })
        await updateProperty('type', parseInt(type))
    }

    const updateProperty = async (field, value) => {
        try {
            await editCreation({
                variables: {
                    id: parseInt(id),
                    data: {
                        [field]: value,
                    },
                },
            })
        } catch (err) {
            console.log('editCreation: ', err)
        }
    }

    const addAuthor = async () => {
        try {
            await addCreationAuthor({
                variables: {
                    data: {
                        ...author,
                        creationId: parseInt(id),
                    },
                },
            })

            setAuthor(authorState)
            refetch()
        } catch (err) {
            console.log('addAuthor: ', err)
        }
    }

    const removeAuthor = async (authorId) => {
        try {
            await removeCreationAuthor({
                variables: {
                    id: parseInt(authorId),
                },
            })

            refetch()
        } catch (err) {
            console.log('removeAuthor: ', err)
        }
    }

    const handleRemoveCreation = async () => {
        try {
            const res = await removeCreation({
                variables: {
                    id: parseInt(id),
                },
            })

            if (!res?.data?.removeCreation || res.data.removeCreation === 'FAIL') return

            navigate('/creations')
        } catch (err) {
            console.log('handleRemoveCreation: ', err)
        }
    }

    const loadUsers = async (input) => {
        if (input.length < 2) return
        const res = await searchUsers({
            input,
        })
        return res?.data?.searchUsers
    }

    const handleSelectAuthor = (option) => {
        if (option.__isNew__) {
            setAuthor({
                ...author,
                authorName: option.value,
                ipi: '',
            })
            return
        }
        setAuthor({
            ...author,
            authorName: option.name,
            ipi: option.ipi,
        })
    }

    const handleAuthorClick = (user) => {
        if (!user?.id) return
        navigate(`/user/${user.id}`)
    }

    if (loading) return <ActivityIndicator />

    return (
        <div className='creation'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ item.title }</h1>
                    <Button
                        label={ t('creations.delete') }
                        onClick={() => setDeleteModal(true)}
                        icon={<DeleteIcon />}
                    />
                </div>
                <div className='creation-content'>
                    <div className='creation-meta'>
                        <div className='creation-meta--item'>
                            <Input
                                label={ t('creation.title') }
                                value={item.title}
                                onChange={(e) => setField('title', e.target.value)}
                            />
                        </div>
                        <div className='creation-meta--item'>
                            <Input
                                label={ t('creation.original_title') }
                                value={item.originalTitle}
                                onChange={(e) => setField('originalTitle', e.target.value)}
                            />
                        </div>
                        <div className='creation-meta--item'>
                            <Input
                                label={ t('creation.year') }
                                value={item.year}
                                onChange={(e) => setField('year', e.target.value)}
                            />
                        </div>
                        <div className='creation-meta--item'>
                            <Input
                                label={ t('creation.producer') }
                                value={item.producer}
                                onChange={(e) => setField('producer', e.target.value)}
                            />
                        </div>
                        <div className='creation-meta--item'>
                            <Input
                                label={ t('creation.country') }
                                value={item.country}
                                onChange={(e) => setField('country', e.target.value)}
                            />
                        </div>
                        <div className='creation-meta--item creation-meta--item-tvshow'>
                            <Input
                                label={ t('creation.season') }
                                value={item.season}
                                onChange={(e) => setField('season', e.target.value)}
                            />
                            <Input
                                label={ t('creation.episode') }
                                value={item.episode}
                                onChange={(e) => setField('episode', e.target.value)}
                            />
                        </div>
                        <div className='creation-meta--item'>
                            <DurationInput
                                label={ t('creations.duration') }
                                onChange={(seconds) => setField('duration', seconds)}
                                initialValue={ item.duration }
                            />
                            <p>
                                {
                                    `${padWithZero(secondsToHms(item.duration).h, 2)}:${padWithZero(secondsToHms(item.duration).m, 2)}:${padWithZero(secondsToHms(item.duration).s, 2)}`
                                }
                            </p>
                        </div>
                        <div className='creation-meta--item'>
                            <Input
                                label={ t('creation.ida_ref') }
                                value={item.idaRef}
                                onChange={(e) => setField('idaRef', e.target.value)}
                                disabled={true}
                            />
                        </div>
                        <div className='creation-meta--item'>
                            <SelectInput
                                placeholder={t('creation.creation_type')}
                                options={types}
                                value={types.find(x => x.id === item?.type)}
                                getOptionLabel={(option) => t(option.name)}
                                getOptionValue={(option) => option.id}
                                onChange={(option) => changeType(option.id)}
                            />
                        </div>
                        <div className='creation-meta--item'>
                            <Checkbox
                                label={`${t('creation.blacklist')}`}
                                value={item.blacklisted}
                                onChange={(e) => setField('blacklisted', e.target.checked)}
                            />
                        </div>
                    </div>
                
                    <Authors
                        data={data.getCreation.authors}
                        onRefresh={() => refetch()}
                    />
                </div>
            </div>
            <ConfirmModal
                show={deleteModal}
                confirmLabel={t('creation.remove')}
                close={() => setDeleteModal(false)}
                onConfirm={handleRemoveCreation}
                title={t('creation.confirm_delete')}
            />
        </div>
    )
}

export default CreationScreen