import React, { useState } from 'react'
import { EditHeir, RemoveHeir } from '../graphql/mutations'
import { useMutation } from '@apollo/client'
import Button from './Button'
import { useTranslation } from 'react-i18next'
import Input from './Input'

const HeirItem = ({
    data,
    onRemove,
    editDisabled,
}) => {

    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [item, setItem] = useState(data)
    const [edit] = useMutation(EditHeir)
    const [remove] = useMutation(RemoveHeir)

    const update = async (field, value) => {
        try {
            await edit({
                variables: {
                    id: parseInt(item.id),
                    data: {
                        [field]: value,
                    },
                },
            })
        } catch (err) {
            console.log('update: ', err)
        }
    }

    const removeHeir = async () => {
        try {
            await remove({
                variables: {
                    id: parseInt(item.id),
                },
            })

            if (onRemove) onRemove()
        } catch (err) {
            console.log('remove: ', err)
        }
    }

    const setField = (field, value) => {
        setItem({
            ...item,
            [field]: value,
        })

        let finalValue = value

        if (field === 'percentage') finalValue = parseFloat(value)

        update(field, finalValue)
    }

    return (
        <div className={`user-heirs--item${open ? ' user-heirs--item-open' : ''}`}>
            <h4 onClick={() => setOpen(!open)}>
                <span>{ item.heir.name }</span>
            </h4>
            <div className={`user-heirs--item-content`}>
                {
                    editDisabled ?
                    <>
                        <Input
                            disabled={true}
                            className={'user-heirs--item-percentage'}
                            label={ t('user.heir_percentage') }
                            value={item.percentage}
                            suffix={'%'}
                            type={'number'}
                        />
                    </>
                    :
                    <>
                        <Input
                            className={'user-heirs--item-percentage'}
                            label={ t('user.heir_percentage') }
                            value={item.percentage}
                            onChange={(e) => setField('percentage', e.target.value)}
                            suffix={'%'}
                            type={'number'}
                        />
                        <Button
                            className={'btn-small'}
                            label={ t('user.heir_delete') }
                            onClick={removeHeir}
                        />
                    </>
                }
            </div>
        </div>
    )
}

export default HeirItem