import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { GetProfile, GetUserForeign, GetUserForeignTotal } from '../../graphql/queries'
import './style.scss'
import FilterTable from '../../components/FilterTable'
import ForeignFilters from '../../components/ForeignFilters'
import Total from '../../components/Total'
import { formatPrice } from '../../util/format'

const includeFields = [
    {
        value: 'creation',
        label: 'Teos',
    },
    {
        value: 'channel',
        label: 'Kanal',
    },
    {
        value: 'society',
        label: 'Ühing',
    },
    {
        value: 'regno',
        label: 'Reg. kood',
    },
    {
        value: 'country',
        label: 'Riik',
    },
    {
        value: 'sum',
        label: 'Summa',
    },
    {
        value: 'fee',
        label: 'Teenustasud',
    },
    {
        value: 'tax',
        label: 'Tulumaks',
    },
    {
        value: 'total',
        label: 'Väljamakse',
    },
]

const UserForeignScreen = ({
    authorId,
    role,
}) => {

    const { t } = useTranslation()
    const tableRef = useRef()
    const filtersRef = useRef()
    const [currentAuthor, setCurrentAuthor] = useState(null)
    const [extraFilters, setExtraFilters] = useState()
    const [total, setTotal] = useState(null)

    const { refetch: getTotal } = useQuery(GetUserForeignTotal, {
        skip: true,
    })

    useEffect(() => {
        (async () => {
            const res = await getTotal({
                year: parseInt(extraFilters?.year),
                type: extraFilters?.type,
                ...(role !== 'author' && authorId && {
                    authorId: parseInt(authorId),
                }),
            })

            if (res?.data?.getUserForeignTotal) setTotal(res?.data?.getUserForeignTotal)
        })()
    }, [extraFilters])

    useQuery(GetProfile, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (!data?.getProfile?.authors) return
            const heir = data.getProfile.authors.find(x => x.author.id === parseInt(authorId))
            if (heir) setCurrentAuthor(heir)
        },
    })

    const fieldConditions = (row, field) => {
        if (['sum', 'total', 'tax', 'fee'].includes(field)) {
            if (authorId) {
                if (!currentAuthor) return ''
                return row[field] ? `${formatPrice(parseFloat(row[field]) * (currentAuthor.percentage / 100))} ${row.currency}` : ''
            }
            return row[field] ? `${formatPrice(row[field])} ${row.currency}` : ''
        }
        return row[field]
    }

    const handleFilterChange = async (filters) => {
        setExtraFilters(filters)
    }

    if (role !== 'author' && !authorId) return <></>

    return (
        <div className='user-foreign'>
            <div className='inner'>
                <div className='below-title'>
                    <ForeignFilters
                        ref={filtersRef}
                        onFilterChange={handleFilterChange}
                        allow={{
                            search: true,
                            year: true,
                        }}
                    />
                    <Total
                        total={total}
                        hidePayed={true}
                    />
                </div>
                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={GetUserForeign}
                        queryKey={'getUserForeign'}
                        hideInputs={true}
                        fieldConditions={fieldConditions}
                        includeFields={includeFields}
                        queryOptions={{
                            variables: {
                                authorId: authorId ? parseInt(authorId) : null,
                            },
                        }}
                        initialOrderBy={'creation'}
                        initialSort={'ASC'}
                        extraFilters={extraFilters}
                        useExtraFilters={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default UserForeignScreen