import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import Input from './Input'
import { GetUnrecognizedFilters } from '../graphql/queries'
import SelectInput from './SelectInput'
import SearchIcon from './icons/search'
import Checkbox from './Checkbox'
import { useSearchParams } from 'react-router-dom'

const initialYears = [
    {
        value: null,
        label: 'Kõik',
    },
]

const initialDist = [
    {
        value: null,
        label: 'Kõik',
    },
]

const initialState = {
    query: null,
    year: null,
    blacklisted: false,
    distribution: null,
    hasAuthors: false,
}

const UnrecognizedFilters = forwardRef(({
    onFilterChange,
}, ref) => {

    const { t } = useTranslation()
    const [filters, setFilters] = useState(initialState)
    const [years, setYears] = useState(initialYears)
    const [distributions, setDistributions] = useState(initialDist)
    const [searchParams, setSearchParams] = useSearchParams()

    const { refetch } = useQuery(GetUnrecognizedFilters, {
        onCompleted: (data) => {
            let yearList = []
            let distList = []

            if (data?.getUnrecognizedFilters) {
                if (data.getUnrecognizedFilters.years) {
                    yearList = data.getUnrecognizedFilters.years.map(x => ({
                        value: x,
                        label: x,
                    }))

                    setYears([
                        ...years,
                        ...yearList,
                    ])
                }

                if (data.getUnrecognizedFilters.distributions) {
                    distList = data.getUnrecognizedFilters.distributions.map(x => ({
                        value: x,
                        label: x,
                    }))

                    setDistributions([
                        ...distributions,
                        ...distList,
                    ])
                }
            }
        },
    })

    useEffect(() => {
        const loadFilters = {
            query: null,
            year: null,
            blacklisted: false,
            distribution: null,
            hasAuthors: false,
        }

        for (const [param, value] of searchParams.entries()) {
            if (param === 'page') continue

            let finalValue = value

            if (param === 'year') finalValue = parseInt(value)
            if (param === 'hasAuthors') finalValue = value === 'true' ? true : false

            loadFilters[param] = finalValue
        }

        setFilters({
            ...loadFilters,
        })

        if (onFilterChange) onFilterChange({
            ...loadFilters,
        })
    }, [searchParams])

    useImperativeHandle(ref, () => ({
        refresh () {
            refetch()
        },
    }))

    const assignSearchParams = (params) => {
        const newParams = {}
        for (const [key, value] of Object.entries(params)) {
            if (!value) continue
            newParams[key] = value
        }

        setSearchParams({
            ...newParams,
            page: 1,
        })
    }

    const setField = (field, value) => {
        const newVal = {
            ...filters,
            [field]: value,
        }

        setFilters(newVal)
        assignSearchParams(newVal)

        if (onFilterChange) onFilterChange(newVal)
    }

    return (
        <div className='filters'>
            <div className='filters-search'>
                <Input
                    placeholder={ t('filters.search_views') }
                    onChange={(e) => setField('query', e.target.value)}
                    value={filters.query}
                    icon={<SearchIcon />}
                    label={ t('filters.search_label') }
                />
            </div>
            <SelectInput
                options={years}
                onChange={(option) => setField('year', parseInt(option.value))}
                value={years.find(x => (x.value ? parseInt(x.value) : null) === filters.year)}
                placeholder={t('filters.year')}
                label={ t('filters.year_label') }
            />
            <SelectInput
                options={distributions}
                onChange={(option) => setField('distribution', option.value)}
                value={distributions.find(x => x.value === filters.distribution)}
                placeholder={t('filters.distribution')}
                label={ t('filters.distribution_label') }
            />
            <Checkbox
                label={`${t('filters.with_authors')}`}
                value={filters.hasAuthors}
                onChange={(e) => setField('hasAuthors', e.target.checked ? true : false)}
            />
        </div>
    )
})

export default UnrecognizedFilters