import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import Button from './Button'
import Input from './Input'
import Modal from './Modal'
import { AddBonus } from '../graphql/mutations'
import DateInput from './DateInput'
import { parseDate } from '../util/format'
import { SearchUsers } from '../graphql/queries'
import CreatableInput from './CreatableInput'

const initialState = {
    name: '',
    label: '',
    description: '',
    date: '',
    sum: '',
    pid: '',
}

const initialErrorsState = {
    name: null,
    label: null,
    description: null,
    date: null,
    sum: null,
    pid: null,
    generic: null,
}

const AddBonusModal = ({
    show,
    close,
    onSuccess,
}) => {

    const { t } = useTranslation()
    const [item, setItem] = useState(initialState)
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState(initialErrorsState)
    const [add] = useMutation(AddBonus)
    const { refetch: searchUsers } = useQuery(SearchUsers, {
        skip: true,
    })

    const loadUsers = async (input) => {
        if (input.length < 2) return
        const res = await searchUsers({
            input,
        })
        return res?.data?.searchUsers
    }

    const handleAdd = async () => {
        if (hasInputErrors()) return
        setLoading(true)
        try {
            const dateObj = new Date(item.date)
            const date = Date.UTC(
                dateObj.getFullYear(),
                dateObj.getMonth(),
                dateObj.getDate(),
            )
            const res = await add({
                variables: {
                    data: {
                        ...item,
                        sum: parseFloat(item.sum),
                        date,
                    },
                },
            })

            if (!res?.data?.addBonus || res.data.addBonus === 'FAIL') {
                setErrors({
                    ...errors,
                    generic: t('bonuses.add_error'),
                })
                return
            }

            if (res.data.addBonus === 'EXISTS') {
                setErrors({
                    ...errors,
                    generic: t('bonuses.existing_error'),
                })
                return
            }

            if (onSuccess) onSuccess(res.data.addBonus)
        } catch (err) {
            setErrors({
                ...errors,
                generic: t('bonuses.add_error'),
            })
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const setField = (field, value) => {
        setErrors({
            ...errors,
            [field]: null,
        })
        setItem({
            ...item,
            [field]: value,
        })
    }

    const handleClose = () => {
        if (close) close()
        setErrors(initialErrorsState)
        setItem(initialState)
    }


    const hasInputErrors = () => {
        let hasErrors = false
        const errorState = {
            ...initialErrorsState,
        }

        if (!item.name) {
            hasErrors = true
            errorState.name = t('reports.fill_field')
        }

        if (!item.label) {
            hasErrors = true
            errorState.label = t('reports.fill_field')
        }

        if (!item.pid) {
            hasErrors = true
            errorState.pid = t('reports.fill_field')
        }

        if (!item.date) {
            hasErrors = true
            errorState.date = t('reports.fill_field')
        }

        if (!item.sum) {
            hasErrors = true
            errorState.sum = t('reports.fill_field')
        }

        setErrors({
            ...errorState,
        })
        return hasErrors
    }

    const handleSelectAuthor = (option) => {
        if (option.__isNew__) {
            setItem({
                ...item,
                name: option.value,
                pid: '',
            })
            return
        }
        setItem({
            ...item,
            name: option.name,
            pid: option.pid,
        })
    }

    return (
        <Modal
            className={'modal-bonus'}
            show={show}
            close={handleClose}
            title={ t('bonuses.add_new') }
        >
            <CreatableInput
                label={t('bonuses.name')}
                placeholder={t('bonuses.name')}
                load={loadUsers}
                getOptionLabel={(option) => option.name || option.label}
                getOptionValue={(option) => option.id || option.value}
                onChange={handleSelectAuthor}
                loadingMessage={() => t('users.loading_users')}
                noOptionsMessage={() => t('users.type_to_search')}
            />
            <Input
                label={ t('bonuses.label') }
                value={item.label}
                onChange={(e) => setField('label', e.target.value)}
                error={errors.label}
            />
            <Input
                label={ t('bonuses.pid') }
                value={item.pid}
                onChange={(e) => setField('pid', e.target.value)}
                error={errors.pid}
            />
            <Input
                label={ t('bonuses.description') }
                value={item.description}
                onChange={(e) => setField('description', e.target.value)}
            />
            <Input
                label={ t('bonuses.sum') }
                value={item.sum}
                type={'number'}
                onChange={(e) => setField('sum', e.target.value)}
                error={errors.sum}
            />
            <DateInput
                label={t('bonuses.date')}
                value={item.date ? new Date(item.date) : null}
                onChange={(val) => setField('date', parseDate(val))}
                error={errors.date}
            />

            {
                errors.generic ?
                <div className='error-message'>
                    <span>{ errors.generic }</span>
                </div>
                :
                <></>
            }

            <Button
                label={ t('bonuses.add_new') }
                onClick={handleAdd}
                loading={loading}
            />
        </Modal>
    )
}

export default AddBonusModal