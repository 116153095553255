import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ProvideAuth } from './providers/Auth'
import Routing from './Routing'
import LoadingComponent from './components/Loading'

const renderFallback = () => (
  <LoadingComponent />
)

const App = () => {
  return (
    <Suspense fallback={renderFallback()}>
      <BrowserRouter>
        <ProvideAuth>
          <Routing />
        </ProvideAuth>
      </BrowserRouter>
    </Suspense>
  )
}

export default App