import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import SelectInput from '../../components/SelectInput'
import { useMutation, useQuery } from '@apollo/client'
import { GetAuthorRoles, GetCreationTypes, GetSociety } from '../../graphql/queries'
import './style.scss'
import ActivityIndicator from '../../components/ActivityIndicator'
import Input from '../../components/Input'
import { AddItemToSociety, EditSociety, RemoveItemFromSociety, RemoveSociety } from '../../graphql/mutations'
import Button from '../../components/Button'
import ConfirmModal from '../../components/ConfirmModal'

const initialState = {
    name: '',
    code: '',
    country: '',
    roles: [],
    types: [],
}

const SocietyScreen = () => {

    const { t } = useTranslation()
    const { id } = useParams()
    const navigate = useNavigate()
    const [remove] = useMutation(RemoveSociety)
    const [edit] = useMutation(EditSociety)
    const [addItem] = useMutation(AddItemToSociety)
    const [removeItem] = useMutation(RemoveItemFromSociety)
    const [item, setItem] = useState(initialState)
    const [role, setRole] = useState()
    const [type, setType] = useState()
    const [deleteModal, setDeleteModal] = useState()

    const { data: roleData } = useQuery(GetAuthorRoles)
    const { data: typeData } = useQuery(GetCreationTypes)

    const { refetch, loading } = useQuery(GetSociety, {
        variables: {
            id: parseInt(id),
        },
        onCompleted: (data) => {
            if (!data?.getSociety) return
            setItem(data.getSociety)

            console.log(data.getSociety)
        },
    })

    const setField = (field, value) => {
        setItem({
            ...item,
            [field]: value,
        })

        let finalValue = value
        
        if (['code'].includes(field)) finalValue = parseInt(value)

        updateProperty(field, finalValue)
    }

    const updateProperty = async (field, value) => {
        try {
            await edit({
                variables: {
                    id: parseInt(id),
                    data: {
                        [field]: value,
                    },
                },
            })
        } catch (err) {
            console.log('updateProperty: ', err)
        }
    }

    const handleRemove = async () => {
        try {
            const res = await remove({
                variables: {
                    id: parseInt(id),
                },
            })

            if (!res?.data?.removeSociety || res.data.removeSociety === 'FAIL') return

            navigate('/societies')
        } catch (err) {
            console.log('handleRemove: ', err)
        }
    }

    const handleAddType = async () => {
        if (!type) return

        const data = {
            id: parseInt(id),
            typeId: parseInt(type),
        }

        try {
            const res = await addItem({
                variables: data,
            })

            if (!res?.data?.addItemToSociety || res.data.addItemToSociety === 'FAIL') return

            setType(null)
            refetch()
        } catch (err) {
            console.log('handleAddRole: ', err)
        }
    }

    const handleAddRole = async () => {
        if (!role) return

        const data = {
            id: parseInt(id),
            roleId: parseInt(role),
        }

        try {
            const res = await addItem({
                variables: data,
            })

            if (!res?.data?.addItemToSociety || res.data.addItemToSociety === 'FAIL') return

            setRole(null)
            refetch()
        } catch (err) {
            console.log('handleAddRole: ', err)
        }
    }

    const handleItemRemove = async ({
        roleId,
        typeId,
    }) => {
        const data = {}

        if (roleId) data.roleId = parseInt(roleId)
        if (typeId) data.typeId = parseInt(typeId)

        console.log(data)

        try {
            const res = await removeItem({
                variables: data,
            })

            if (!res?.data?.removeItemFromSociety || res.data.removeItemFromSociety === 'FAIL') return

            refetch()
        } catch (err) {
            console.log('handleItemRemove: ', err)
        }
    }

    if (loading) return <ActivityIndicator />

    return (
        <div className='society'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ t('society.title') }</h1>
                    <Button
                        label={ t('society.remove') }
                        onClick={() => setDeleteModal(true)}
                    />
                </div>
                <div className='society-content'>
                    <div className='society-info'>
                        <h3>{ t('society.info') }</h3>
                        <Input
                            label={ t('society.name') }
                            value={item.name}
                            onChange={(e) => setField('name', e.target.value)}
                        />
                        <Input
                            label={ t('society.code') }
                            value={item.code}
                            onChange={(e) => setField('code', e.target.value)}
                        />
                        <Input
                            label={ t('society.country') }
                            value={item.country}
                            onChange={(e) => setField('country', e.target.value)}
                        />
                    </div>
                
                    <div className='society-roles'>
                        <h3>{ t('society.roles') }</h3>
                        <div className='society-roles--list'>
                            {
                                item.roles && item.roles.length > 0 ?
                                item.roles.map((roleItem) =>
                                    <div
                                        className='society-roles--item'
                                        key={roleItem.id}
                                    >
                                        <span>{ t(roleItem.role.name) }</span>
                                        <Button
                                            className={'btn-small'}
                                            label={ t('society.delete_role') }
                                            onClick={() => handleItemRemove({ roleId: roleItem.id })}
                                        />
                                    </div>
                                )
                                :
                                <p>{ t('society.no_roles') }</p>
                            }
                        </div>
                        <div className='society-roles--add'>
                            <h4>{ t('society.role_add') }</h4>
                            <div className='society-roles--search'>
                                <SelectInput
                                    options={roleData?.getAuthorRoles ? roleData.getAuthorRoles : []}
                                    placeholder={t('society.select_role')}
                                    getOptionLabel={(option) => t(option.name)}
                                    getOptionValue={(option) => option.id}
                                    onChange={(option) => setRole(option.id)}
                                />
                            </div>
                            <Button
                                label={t('society.role_add')}
                                onClick={handleAddRole}
                            />
                        </div>
                    </div>

                    <div className='society-types'>
                        <h3>{ t('society.types') }</h3>
                        <div className='society-types--list'>
                            {
                                item.types && item.types.length > 0 ?
                                item.types.map((typeItem) =>
                                    <div
                                        className='society-types--item'
                                        key={typeItem.id}
                                    >
                                        <span>{ t(typeItem.type.name) }</span>
                                        <Button
                                            className={'btn-small'}
                                            label={ t('society.delete_type') }
                                            onClick={() => handleItemRemove({ typeId: typeItem.id })}
                                        />
                                    </div>
                                )
                                :
                                <p>{ t('society.no_types') }</p>
                            }
                        </div>
                        <div className='society-types--add'>
                            <h4>{ t('society.type_add') }</h4>
                            <div className='society-types--search'>
                                <SelectInput
                                    options={typeData?.getCreationTypes ? typeData.getCreationTypes : []}
                                    placeholder={t('society.select_type')}
                                    getOptionLabel={(option) => t(option.name)}
                                    getOptionValue={(option) => option.id}
                                    onChange={(option) => setType(option.id)}
                                />
                            </div>
                            <Button
                                label={t('society.type_add')}
                                onClick={handleAddType}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmModal
                show={deleteModal}
                confirmLabel={t('society.remove')}
                close={() => setDeleteModal(false)}
                onConfirm={handleRemove}
                title={t('society.confirm_delete')}
            />
        </div>
    )
}

export default SocietyScreen