import React, { useRef, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { GetForeign, GetForeignTotal } from '../../graphql/queries'
import './style.scss'
import FilterTable from '../../components/FilterTable'
import Button from '../../components/Button'
import ForeignFilters from '../../components/ForeignFilters'
import PlusIcon from '../../components/icons/plus'
import ImportIcon from '../../components/icons/import'
import ExportIcon from '../../components/icons/excel'
import DeleteIcon from '../../components/icons/delete'
import { RemoveForeign } from '../../graphql/mutations'
import Tasks from '../../components/Tasks'
import { TaskTypes } from '../../util/const'
import ImportForeignModal from '../../components/ImportForeignModal'
import AddForeignModal from '../../components/AddForeignModal'
import ExportForeignModal from '../../components/ExportForeignModal'
import Total from '../../components/Total'

const includeFields = [
    {
        value: 'name',
        label: 'Nimi',
    },
    {
        value: 'pid',
        label: 'Isikukood',
    },
    {
        value: 'ipi',
        label: 'IPI NN',
    },
    {
        value: 'creation',
        label: 'Teos',
    },
    {
        value: 'channel',
        label: 'Kanal',
    },
    {
        value: 'society',
        label: 'Ühing',
    },
    {
        value: 'regno',
        label: 'Reg. kood',
    },
    {
        value: 'country',
        label: 'Riik',
    },
    {
        value: 'sum',
        label: 'Summa',
    },
    {
        value: 'fee',
        label: 'Teenustasud',
    },
    {
        value: 'tax',
        label: 'Tulumaks',
    },
    {
        value: 'total',
        label: 'Väljamakse',
    },
]

const ForeignScreen = () => {

    const { t } = useTranslation()
    const tableRef = useRef()
    const filtersRef = useRef()

    const [extraFilters, setExtraFilters] = useState()
    const [showAddModal, setShowAddModal] = useState(false)
    const [exportModal, setExportModal] = useState(false)
    const [showImportModal, setShowImportModal] = useState(false)
    const [removeForeign] = useMutation(RemoveForeign)
    const [selectedRows, setSelectedRows] = useState([])
    const [total, setTotal] = useState(null)

    useQuery(GetForeignTotal, {
        variables: {
            year: extraFilters?.year,
            query: extraFilters?.query,
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            setTotal(data?.getForeignTotal)
        }
    })

    const handleRowClick = (selection) => {
        if (selectedRows.length === 1 && selectedRows[0] === selection) {
            setSelectedRows([])
            return
        }
        if (Array.isArray(selection)) {
            setSelectedRows(selection)
        } else {
            setSelectedRows([selection])
        }
    }

    const fieldConditions = (row, field) => {
        if (['sum', 'total', 'tax', 'fee'].includes(field)) return row[field] ? `${row[field]} ${row.currency}` : ''
        return row[field]
    }

    const handleSuccess = () => {
        setShowAddModal(false)
        tableRef.current?.refresh()
        filtersRef.current?.refresh()
    }

    const handleFilterChange = async (filters) => {
        setExtraFilters(filters)
    }

    const handleImportSuccess = () => {
        setShowImportModal(false)
        tableRef.current?.refresh()
    }

    const handleDelete = async () => {
        try {
            const res = await removeForeign({
                variables: {
                    ids: selectedRows.map(x => x.id),
                },
            })

            if (res?.data?.removeForeign === 'SUCCESS') {
                tableRef.current?.refresh()
            }

            console.log('Delete foreign:', res)
        } catch (err) {
            console.log('handleDelete', err)
        }
    }

    return (
        <div className='foreign'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ t('foreign.title') }</h1>
                    <Button
                        label={ t('foreign.add_new') }
                        onClick={() => setShowAddModal(true)}
                        icon={<PlusIcon />}
                    />
                    <Button
                        label={ t('foreign.import') }
                        onClick={() => setShowImportModal(true)}
                        icon={<ImportIcon />}
                    />
                    <Button
                        label={ t('reports.export') }
                        onClick={() => setExportModal(true)}
                        icon={<ExportIcon />}
                    />
                    {
                        selectedRows.length > 0 ?
                        <Button
                            label={ t('foreign.delete') }
                            onClick={handleDelete}
                            icon={<DeleteIcon />}
                        />
                        :
                        <></>
                    }
                </div>

                <Tasks
                    type={TaskTypes.BonusImport}
                />

                <div className='below-title'>
                    <ForeignFilters
                        ref={filtersRef}
                        onFilterChange={handleFilterChange}
                        allow={{
                            year: true,
                            search: true,
                        }}
                    />
                    <Total
                        total={total}
                        hidePayed={true}
                    />
                </div>

                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={GetForeign}
                        queryKey={'getForeign'}
                        hideInputs={true}
                        fieldConditions={fieldConditions}
                        includeFields={includeFields}
                        initialOrderBy={'name'}
                        onRowClick={handleRowClick}
                        initialSort={'ASC'}
                        extraFilters={extraFilters}
                        useExtraFilters={true}
                        activeRows={selectedRows}
                        allowSelect={true}
                    />
                </div>
            </div>
            <AddForeignModal
                show={showAddModal}
                close={() => setShowAddModal(false)}
                onSuccess={handleSuccess}
            />
            <ImportForeignModal
                show={showImportModal}
                close={() => setShowImportModal(false)}
                onSuccess={handleImportSuccess}
            />
            <ExportForeignModal
                show={exportModal}
                close={() => setExportModal(false)}
            />
        </div>
    )
}

export default ForeignScreen