import React from 'react'
import InfoIcon from './icons/info'

const SuccessMessage = ({
    message,
}) => {
    if (!message) return <></>
    return (
        <div className='success-message--container'>
            <div className='success-message--message'>
                <InfoIcon />
                <span>{ message }</span>
            </div>
        </div>
    )
}

export default SuccessMessage