import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { GetProfile, GetUserDistributionReports, GetUserDistributionTotal, GetViewFilters } from '../../graphql/queries'
import './style.scss'
import FilterTable from '../../components/FilterTable'
import { ReportTypes } from '../../util/const'
import { formatDate, formatPrice } from '../../util/format'
import ReportFilters from '../../components/ReportFilters'
import { Countries } from '../../util/countries'
import { useAuth } from '../../providers/Auth'
import Total from '../../components/Total'

const initialFilterState = {
    years: [],
    distributions: [],
    types: [
        {
            label: 'TV',
            value: ReportTypes.TV,
        },
        {
            label: 'VOD',
            value: ReportTypes.VOD,
        },
    ],
}

const includeFields = [
    {
        value: 'name',
        label: 'Jaotus',
    },
    {
        value: 'year',
        label: 'Aasta',
    },
    {
        value: 'total',
        label: 'Summa',
    },
    {
        value: 'country',
        label: 'Riik',
    },
    {
        value: 'createdAt',
        label: 'Kuupäev',
    },
]

const UserReportsScreen = ({
    authorId,
    role,
}) => {

    const { t } = useTranslation()
    const { user } = useAuth()
    const navigate = useNavigate()
    const tableRef = useRef()
    const filtersRef = useRef()

    const [filters, setFilters] = useState(initialFilterState)
    const [extraFilters, setExtraFilters] = useState()
    const [currentAuthor, setCurrentAuthor] = useState(null)
    const [total, setTotal] = useState(null)

    const { refetch: getTotal } = useQuery(GetUserDistributionTotal, {
        skip: true,
    })

    useEffect(() => {
        (async () => {
            if (extraFilters?.year && extraFilters?.type) {
                const res = await getTotal({
                    year: parseInt(extraFilters.year),
                    type: extraFilters.type,
                    ...(role !== 'author' && authorId && {
                        authorId: parseInt(authorId),
                    }),
                })

                if (res?.data?.getUserDistributionTotal) setTotal(res?.data?.getUserDistributionTotal)
            }
        })()
    }, [extraFilters])

    useQuery(GetProfile, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (!data?.getProfile?.authors) return
            const heir = data.getProfile.authors.find(x => x.author.id === parseInt(authorId))
            if (heir) setCurrentAuthor(heir)
        },
    })

    useQuery(GetViewFilters, {
        onCompleted: (data) => {
            if (data?.getViewFilters) {
                let yearList = []
                let distList = []
                if (data.getViewFilters.years) {
                    yearList = data.getViewFilters.years.map(x => ({
                        value: x,
                        label: x,
                    }))
                }

                if (data.getViewFilters.distributions) {
                    distList = data.getViewFilters.distributions.map(x => ({
                        value: x,
                        label: x,
                    }))
                }

                setFilters({
                    ...filters,
                    distributions: distList,
                    years: yearList,
                })
            }
        },
    })

    const handleRowClick = (row) => {
        if (authorId) return navigate(`/user-distribution/${row?.report?.id}/${row?.name}/${authorId}`)
        navigate(`/user-distribution/${row?.report?.id}/${row?.name}`)
    }

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'total':
                if (authorId && currentAuthor) return `${formatPrice(parseFloat(row[field]) * (parseFloat(currentAuthor.percentage) / 100))} €`
                if (!authorId) return `${formatPrice(row[field])} €`
                return ''
            case 'createdAt':
                return formatDate(row?.report ? row.report[field] : null)
            case 'year':
                return row.report?.year
            case 'type':
                return row.report?.type
            case 'country':
                return Countries.country[row[field]]
            default:
                return row[field]
        }
    }

    const handleFilterChange = async (filters) => {
        setExtraFilters(filters)
    }

    if (role !== 'author' && !authorId) return <></>

    return (
        <div className='user-reports'>
            <div className='inner'>
                <div className='below-title'>
                    <ReportFilters
                        ref={filtersRef}
                        onFilterChange={handleFilterChange}
                        allowed={{
                            type: true,
                            year: true,
                            distribution: true,
                        }}
                    />
                    <Total
                        total={total}
                        hidePayed={true}
                    />
                </div>
                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={GetUserDistributionReports}
                        fieldConditions={fieldConditions}
                        queryKey={'getUserDistributionReports'}
                        queryOptions={{
                            variables: {
                                authorId: parseInt(authorId),
                            },
                        }}
                        hideInputs={true}
                        initialOrderBy={'year'}
                        onRowClick={handleRowClick}
                        includeFields={includeFields}
                        extraFilters={extraFilters}
                        useExtraFilters={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default UserReportsScreen