import React from 'react'
import { useNavigate } from 'react-router-dom'

const AppFooter = () => {
    const navigate = useNavigate()

    return (
        <footer className={`app-footer`}>
            <div className='app-footer--inner'>
                <div className='brand'>
                    <div onClick={() => navigate('/')} className='brand-logo'></div>
                    <div onClick={() => navigate('/')} className='eaal-logo'></div>
                </div>
                <div className='partners'>
                    <div className='partners--tmdb'></div>
                </div>
            </div>
        </footer>
    )
}

export default AppFooter