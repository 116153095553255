import React from 'react'

const MailIcon = ({ fill }) => {

    return (
        <svg version="1.1" fill={fill ? fill : '#000'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
            <path d="M425.367,72.125H86.633C38.752,72.125,0,111.131,0,158.759v194.482
                c0,47.839,38.98,86.633,86.633,86.633h338.734c47.741,0,86.633-38.888,86.633-86.633V158.759
                C512,111.069,473.187,72.125,425.367,72.125z M425.367,110.411c9.804,0,18.928,2.942,26.556,7.976l-184.231,120.58
                c-7.102,4.649-16.282,4.649-23.386,0l-184.23-120.58C76.732,107.394,58.236,110.411,425.367,110.411z M39.03,150.368
                l126.776,82.975L38.286,350.222C38.286,142.169,37.967,156.414,39.03,150.368z M425.367,401.589H86.633
                c-12.865,0-24.563-5.061-33.235-13.284l145.463-133.326c59.59,39.003,66.629,31.186,114.278,0l145.463,133.326
                C449.931,396.528,438.232,401.589,425.367,401.589z M473.716,350.222l-127.52-116.879l126.776-82.975
                C474.035,156.414,473.716,142.206,473.716,350.222z"/>
        </svg>
    )
}

export default MailIcon