import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import Button from './Button'
import Modal from './Modal'
import DownloadIcon from './icons/download'
import { GetForeignExcel } from '../graphql/queries'
import { formatDate } from '../util/format'
import ForeignFilters from './ForeignFilters'

const initialErrorsState = {
    generic: null,
}

const ExportForeignModal = ({
    show,
    close,
    onSuccess,
}) => {

    const { t } = useTranslation()

    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState(initialErrorsState)
    const [fileName, setFileName] = useState(null)
    const [link, setLink] = useState(null)
    const [filters, setFilters] = useState()

    const { refetch } = useQuery(GetForeignExcel, {
        skip: true,
        fetchPolicy: 'no-cache',
    })

    const handleExport = async () => {
        setLoading(true)
        try {
            const res = await refetch({
                year: parseInt(filters.year),
            })

            setLink(`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data.getForeignExcel}`)
            setFileName(
                `${filters.year} boonused ${formatDate((new Date()).toISOString())}.xlsx`
            )

            if (!res?.data?.getForeignExcel || res.data.getForeignExcel === 'FAIL') {
                setErrors({
                    ...errors,
                    generic: t('reports.excel_error'),
                })
                return
            }

            if (onSuccess) onSuccess(res.data.getForeignExcel)
        } catch (err) {
            setErrors({
                ...errors,
                generic: t('reports.excel_error'),
            })
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const handleClose = () => {
        if (close) close()
        setErrors(initialErrorsState)
        setLink(null)
        setFileName(null)
        setFilters(null)
    }

    const handleFilterChange = async (filters) => {
        setLink(null)
        setFileName(null)
        setFilters(filters)
    }

    return (
        <Modal
            className={'modal-export modal-export--foreign'}
            show={show}
            close={handleClose}
            title={ t('reports.export') }
        >
            <h4>{ t('reports.filters') }</h4>
            <ForeignFilters
                onFilterChange={handleFilterChange}
                allow={{
                    year: true,
                }}
            />

            {
                errors.generic ?
                <div className='error-message'>
                    <span>{ errors.generic }</span>
                </div>
                :
                <></>
            }

            <div className='modal-export--actions'>
                <Button
                    label={ t('reports.download_excel') }
                    link={link}
                    download={fileName}
                    disabled={!link || !fileName}
                    icon={<DownloadIcon />}
                />
                <Button
                    label={ t('reports.start_export') }
                    onClick={handleExport}
                    loading={loading}
                />
            </div>
        </Modal>
    )
}

export default ExportForeignModal