import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import Button from './Button'
import Input from './Input'
import Modal from './Modal'
import { AddForeign } from '../graphql/mutations'
import { SearchUsers } from '../graphql/queries'
import CreatableInput from './CreatableInput'

const initialState = {
    name: '',
    pid: '',
    ipi: '',
    creation: '',
    channel: '',
    country: '',
    society: '',
    regno: '',
    type: '',
    currency: '',
    sum: '',
    tax: '',
    total: '',
}

const initialErrorsState = {
    name: null,
    pid: null,
    ipi: null,
    creation: null,
    channel: null,
    country: null,
    society: null,
    regno: null,
    type: null,
    currency: null,
    sum: null,
    tax: null,
    total: null,
    generic: null,
}

const AddForeignModal = ({
    show,
    close,
    onSuccess,
}) => {

    const { t } = useTranslation()
    const [item, setItem] = useState(initialState)
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState(initialErrorsState)
    const [add] = useMutation(AddForeign)
    const { refetch: searchUsers } = useQuery(SearchUsers, {
        skip: true,
    })

    const loadUsers = async (input) => {
        if (input.length < 2) return
        const res = await searchUsers({
            input,
        })
        return res?.data?.searchUsers
    }

    const handleAdd = async () => {
        if (hasInputErrors()) return
        setLoading(true)
        try {
            const dateObj = new Date(item.date)
            const date = Date.UTC(
                dateObj.getFullYear(),
                dateObj.getMonth(),
                dateObj.getDate(),
            )
            const res = await add({
                variables: {
                    data: {
                        ...item,
                        sum: parseFloat(item.sum),
                        tax: parseFloat(item.tax),
                        total: parseFloat(item.total),
                    },
                },
            })

            if (!res?.data?.addForeign || res.data.addForeign === 'FAIL') {
                setErrors({
                    ...errors,
                    generic: t('foreign.add_error'),
                })
                return
            }

            if (onSuccess) onSuccess(res.data.addForeign)
        } catch (err) {
            setErrors({
                ...errors,
                generic: t('foreign.add_error'),
            })
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const setField = (field, value) => {
        setErrors({
            ...errors,
            [field]: null,
        })
        setItem({
            ...item,
            [field]: value,
        })
    }

    const handleClose = () => {
        if (close) close()
        setErrors(initialErrorsState)
        setItem(initialState)
    }


    const hasInputErrors = () => {
        let hasErrors = false
        const errorState = {
            ...initialErrorsState,
        }

        if (!item.name) {
            hasErrors = true
            errorState.name = t('reports.fill_field')
        }

        if (!item.ipi) {
            hasErrors = true
            errorState.ipi = t('reports.fill_field')
        }

        if (!item.pid) {
            hasErrors = true
            errorState.pid = t('reports.fill_field')
        }

        if (!item.currency) {
            hasErrors = true
            errorState.currency = t('reports.fill_field')
        }

        if (!item.total) {
            hasErrors = true
            errorState.total = t('reports.fill_field')
        }

        if (!item.tax) {
            hasErrors = true
            errorState.tax = t('reports.fill_field')
        }

        if (!item.sum) {
            hasErrors = true
            errorState.sum = t('reports.fill_field')
        }

        setErrors({
            ...errorState,
        })
        return hasErrors
    }

    const handleSelectAuthor = (option) => {
        const errorState = {
            ...initialErrorsState,
        }
        if (option.__isNew__) {
            setItem({
                ...item,
                name: option.value,
                pid: '',
            })
            setErrors({
                ...errorState,
                name: null,
                pid: null,
            })
            return
        }
        setItem({
            ...item,
            name: option.name,
            pid: option.pid,
            ipi: option.ipi,
        })
        setErrors({
            ...errorState,
            name: null,
            pid: null,
            ipi: null,
        })
    }

    return (
        <Modal
            className={'modal-foreign'}
            show={show}
            close={handleClose}
            title={ t('foreign.add_new') }
        >
            <div className='modal-foreign--inputs'>
                <CreatableInput
                    label={t('foreign.name')}
                    placeholder={t('foreign.name')}
                    load={loadUsers}
                    getOptionLabel={(option) => option.name || option.label}
                    getOptionValue={(option) => option.id || option.value}
                    onChange={handleSelectAuthor}
                    loadingMessage={() => t('users.loading_users')}
                    noOptionsMessage={() => t('users.type_to_search')}
                    error={errors.name}
                />
                <Input
                    label={ t('foreign.ipi') }
                    value={item.ipi}
                    onChange={(e) => setField('ipi', e.target.value)}
                    error={errors.ipi}
                />
                <Input
                    label={ t('foreign.pid') }
                    value={item.pid}
                    onChange={(e) => setField('pid', e.target.value)}
                    error={errors.pid}
                />
                <Input
                    label={ t('foreign.creation') }
                    value={item.creation}
                    onChange={(e) => setField('creation', e.target.value)}
                />
                <Input
                    label={ t('foreign.channel') }
                    value={item.channel}
                    onChange={(e) => setField('channel', e.target.value)}
                />
                <Input
                    label={ t('foreign.country') }
                    value={item.country}
                    onChange={(e) => setField('country', e.target.value)}
                />
                <Input
                    label={ t('foreign.society') }
                    value={item.society}
                    onChange={(e) => setField('society', e.target.value)}
                />
                <Input
                    label={ t('foreign.regno') }
                    value={item.regno}
                    onChange={(e) => setField('regno', e.target.value)}
                />
                <Input
                    label={ t('foreign.type') }
                    value={item.type}
                    onChange={(e) => setField('type', e.target.value)}
                />
                <Input
                    label={ t('foreign.currency') }
                    value={item.currency}
                    onChange={(e) => setField('currency', e.target.value)}
                    error={errors.currency}
                />
                <Input
                    label={ t('foreign.sum') }
                    value={item.sum}
                    type={'number'}
                    onChange={(e) => setField('sum', e.target.value)}
                    error={errors.sum}
                />
                <Input
                    label={ t('foreign.tax') }
                    value={item.tax}
                    type={'number'}
                    onChange={(e) => setField('tax', e.target.value)}
                    error={errors.tax}
                />
                <Input
                    label={ t('foreign.total') }
                    value={item.total}
                    type={'number'}
                    onChange={(e) => setField('total', e.target.value)}
                    error={errors.total}
                />
            </div>

            {
                errors.generic ?
                <div className='error-message'>
                    <span>{ errors.generic }</span>
                </div>
                :
                <></>
            }

            <Button
                label={ t('foreign.add_new') }
                onClick={handleAdd}
                loading={loading}
            />
        </Modal>
    )
}

export default AddForeignModal