import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { GetAuthorDistributions, GetDistributions, GetProfile, GetReport, GetUser, GetUserDistributions, GetUserDistributionTotal } from '../../graphql/queries'
import './style.scss'
import FilterTable from '../../components/FilterTable'
import { ReportTypes } from '../../util/const'
import { formatDate, formatPrice } from '../../util/format'
import { capitalizeName } from '../../util/string'
import DistributionFilters from '../../components/DistributionFilters'
import { useAuth } from '../../providers/Auth'
import Total from '../../components/Total'

const initialFilterState = {
    years: [],
    distributions: [],
    types: [
        {
            label: 'TV',
            value: ReportTypes.TV,
        },
        {
            label: 'VOD',
            value: ReportTypes.VOD,
        },
    ],
}

const includeFields = [
    {
        value: 'creation',
        label: 'Teos',
    },
    {
        value: 'views',
        label: 'Vaatamised',
    },
    {
        value: 'role',
        label: 'Roll',
    },
    {
        value: 'total',
        label: 'Summa',
    },
    {
        value: 'createdAt',
        label: 'Kuupäev',
    },
]

const UserDistributionScreen = () => {

    const { t } = useTranslation()
    const { id, name, authorId } = useParams()
    const tableRef = useRef()

    const [extraFilters, setExtraFilters] = useState()
    const [currentAuthor, setCurrentAuthor] = useState(null)
    const [total, setTotal] = useState(null)

    const { data: reportData } = useQuery(GetReport, { variables: { id: parseInt(id) } })

    const { refetch: getTotal } = useQuery(GetUserDistributionTotal, {
        skip: true,
    })

    useEffect(() => {
        (async () => {
            if (reportData?.getReport?.year && reportData?.getReport?.type) {
                const res = await getTotal({
                    year: parseInt(reportData.getReport.year),
                    type: reportData.getReport.type,
                    distribution: name,
                    ...(authorId && {
                        authorId: parseInt(authorId),
                    })
                })

                if (res?.data?.getUserDistributionTotal) setTotal(res?.data?.getUserDistributionTotal)
            }
        })()
    }, [reportData])

    useQuery(GetProfile, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (!data?.getProfile?.authors) return
            const heir = data.getProfile.authors.find(x => x.author.id === parseInt(authorId))
            if (heir) setCurrentAuthor(heir)
        },
    })

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'total':
                if (authorId && currentAuthor) return `${formatPrice(parseFloat(row[field]) * (parseFloat(currentAuthor.percentage) / 100))} €`
                if (!authorId) return `${formatPrice(row[field])} €`
                return ''
            case 'createdAt':
                return formatDate(row[field])
            case 'creation':
                return `${capitalizeName(row.creation?.title)} ${row.creation?.year} ${row.creation?.episode ? `- ${row.creation.episode}` : ''}`
            case 'views':
                return row[field]
            case 'authorName':
                return capitalizeName(row[field])
            case 'role':
                return t(row.role?.name)
            default:
                return row[field]
        }
    }

    const handleFilterChange = async (filters) => {
        setExtraFilters(filters)
    }

    const getRowClass = (row) => {
        return row?.user?.id || row?.namedUser?.id ? 'is-user' : ''
    }

    if (authorId && !currentAuthor) return <></>

    return (
        <div className='user-distributions'>
            <div className='inner'>
                <div className='inner-title'>
                    {
                        currentAuthor ?
                        <h1>{ currentAuthor.author.name }</h1>
                        :
                        <h1>{ t('user_reports.title') }</h1>
                    }
                    {
                        reportData?.getReport ?
                        <h4>{ `${name} - ${reportData?.getReport?.type} ${reportData?.getReport?.year}` }</h4>
                        :
                        <></>
                    }
                </div>
                
                <div className='below-title'>
                    <DistributionFilters
                        onFilterChange={handleFilterChange}
                    />
                    <Total
                        total={total}
                        hidePayed={true}
                    />
                </div>

                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={GetUserDistributions}
                        fieldConditions={fieldConditions}
                        queryKey={'getUserDistributions'}
                        hideInputs={true}
                        initialOrderBy={'creation'}
                        queryOptions={{
                            variables: {
                                reportId: parseInt(id),
                                distribution: name,
                                authorId: parseInt(authorId),
                            },
                        }}
                        includeFields={includeFields}
                        extraFilters={extraFilters}
                        useExtraFilters={true}
                        rowClass={getRowClass}
                    />
                </div>
            </div>
        </div>
    )
}

export default UserDistributionScreen