import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { GetSocieties } from '../../graphql/queries'
import './style.scss'
import FilterTable from '../../components/FilterTable'
import AddSocietyModal from '../../components/AddSocietyModal'
import Button from '../../components/Button'
import ImportSocietyModal from '../../components/ImportSocietyModal'
import SocietyFilters from '../../components/SocietyFilters'
import PlusIcon from '../../components/icons/plus'
import ImportIcon from '../../components/icons/import'
import { TaskTypes } from '../../util/const'
import Tasks from '../../components/Tasks'

const includeFields = [
    {
        value: 'name',
        label: 'Nimi',
    },
    {
        value: 'code',
        label: 'Kood',
    },
    {
        value: 'country',
        label: 'Riik',
    },
]

const SocietiesScreen = () => {

    const { t } = useTranslation()
    const tableRef = useRef()
    const navigate = useNavigate()

    const [extraFilters, setExtraFilters] = useState()
    const [showAddModal, setShowAddModal] = useState(false)
    const [showImportModal, setShowImportModal] = useState(false)

    const handleRowClick = (row) => {
        navigate(`/society/${row.id}`)
    }

    const handleSuccess = (societyId) => {
        setShowAddModal(false)
        navigate(`/society/${societyId}`)
    }

    const handleFilterChange = async (filters) => {
        setExtraFilters(filters)
    }

    const handleImportSuccess = () => {
        setShowImportModal(false)
        tableRef.current?.refresh()
    }

    return (
        <div className='societies'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ t('societies.title') }</h1>
                    <Button
                        label={ t('societies.add_new') }
                        onClick={() => setShowAddModal(true)}
                        icon={<PlusIcon />}
                    />
                    <Button
                        label={ t('societies.import') }
                        onClick={() => setShowImportModal(true)}
                        icon={<ImportIcon />}
                    />
                </div>

                <Tasks
                    type={TaskTypes.SocietyImport}
                />
                <SocietyFilters
                    onFilterChange={handleFilterChange}
                />

                <div className='filter-table--container'>
                    <FilterTable
                        ref={tableRef}
                        query={GetSocieties}
                        queryKey={'getSocieties'}
                        hideInputs={true}
                        includeFields={includeFields}
                        initialOrderBy={'name'}
                        onRowClick={handleRowClick}
                        initialSort={'ASC'}
                        extraFilters={extraFilters}
                        useExtraFilters={true}
                    />
                </div>
            </div>
            <AddSocietyModal
                show={showAddModal}
                close={() => setShowAddModal(false)}
                onSuccess={handleSuccess}
            />
            <ImportSocietyModal
                show={showImportModal}
                close={() => setShowImportModal(false)}
                onSuccess={handleImportSuccess}
            />
        </div>
    )
}

export default SocietiesScreen