import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SearchIcon from './icons/search'
import Input from './Input'

const initialState = {
    query: null,
}

const DistributionFilters = ({
    onFilterChange,
}) => {

    const { t } = useTranslation()
    const [filters, setFilters] = useState(initialState)

    useEffect(() => {
        if (onFilterChange) onFilterChange(filters)
    }, [])

    const setField = (field, value) => {
        const newVal = {
            ...filters,
            [field]: value,
        }

        setFilters(newVal)

        if (onFilterChange) onFilterChange(newVal)
    }

    return (
        <div className='filters'>
            <div className='filters-search'>
                <Input
                    placeholder={ t('filters.search') }
                    onChange={(e) => setField('query', e.target.value)}
                    value={filters.query}
                    icon={<SearchIcon />}
                />
            </div>
        </div>
    )
}

export default DistributionFilters