import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from './Button'
import Input from './Input'
import { useAuth } from '../providers/Auth'
import { useQuery, useMutation } from '@apollo/client'
import { IdAuth } from '../graphql/queries'
import { ValidateIDCard } from '../graphql/mutations'

import {
    status,
    authenticate,
    Action,
    ErrorCode,
} from '@web-eid/web-eid-library/web-eid'

const IdCard = ({
    onCancel,
}) => {

    const { t } = useTranslation()
    const { user, signIn } = useAuth()
    const navigate = useNavigate()
    const [error, setError] = useState()
    const [validateIdCard] = useMutation(ValidateIDCard)
    
    const { refetch: idAuth } = useQuery(IdAuth, {
        skip: true,
    })

    useEffect(() => {

        return () => {
            reset()
        }
    }, [])

    const reset = () => {
        setError(null)
    }

    const login = async () => {
        const { data } = await idAuth()
        
        if (data?.idAuth?.nonce) {
            try {
                const res = await authenticate(data.idAuth.nonce)

                if (res?.unverifiedCertificate && res?.signature) {
                    const { data: validateData } = await validateIdCard({
                        variables: {
                            input: res,
                        },
                    })

                    if (validateData.validateIdCard?.token) {
                        signIn(validateData.validateIdCard.token)
                    }
                }
            } catch (err) {
                console.log('ID Auth error:', err.message)

                if (err.code === ErrorCode.ERR_WEBEID_USER_CANCELLED) {
                    handleCancel()
                    return
                }
                setError(err)
            }
        }
    }

    const handleCancel = () => {
        if (onCancel) onCancel()
    }

    const renderError = () => {
        switch (error.code) {
            case ErrorCode.ERR_WEBEID_EXTENSION_UNAVAILABLE:
                return (
                    <p>
                        {
                            `${t('login.missing_plugin')} ${t('login.download')} `
                        }
                        <a href={t('login.plugin_link')} target='_blank'>{ t('login.plugin_link') }</a>
                    </p>
                )
            default:
                return (
                    <p>
                        { error.message }
                    </p>
                )
        }
    }

    return (
        <div className='login-method login-method--smartid'>
            <div className='login-method--logo'>
                <img src='./assets/logos/id-card.webp' />
            </div>
            {
                !error ?
                <div className='login-method--input'>
                    <div className='login-actions'>
                        <Button
                            className={'btn-cancel'}
                            label={t('login.cancel')}
                            onClick={handleCancel}
                        />
                        <Button
                            label={t('login.enter')}
                            onClick={login}
                        />
                    </div>
                </div>
                :
                <div className='login-method--error'>
                    <div className='login-method--error-message'>
                            {
                                renderError()
                            }
                    </div>
                    <div className='login-actions'>
                        <Button
                            className={'btn-cancel'}
                            label={t('login.back')}
                            onClick={handleCancel}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default IdCard