import React from 'react'

const DownloadIcon = ({ fill }) => {

    return (
        <svg version="1.1" baseProfile="tiny" fill={fill ? fill : '#010C3A'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
            <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M356.416,313.749
                l-85.312,85.312c-1.963,1.984-4.331,3.541-6.955,4.629c-2.603,1.067-5.376,1.643-8.149,1.643c-2.773,0-5.547-0.576-8.149-1.643
                c-2.624-1.088-4.992-2.645-6.955-4.629l-85.312-85.312c-8.341-8.341-8.341-21.824,0-30.165s21.824-8.341,30.165,0l48.917,48.917
                V128c0-11.776,9.536-21.333,21.333-21.333s21.333,9.557,21.333,21.333v204.501l48.917-48.917c8.341-8.341,21.824-8.341,30.165,0
                C364.757,291.925,364.757,305.408,356.416,313.749z"/>
        </svg>

    )
}

export default DownloadIcon