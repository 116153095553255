const UserRoles = {
    Admin: 'ADMIN',
    Member: 'MEMBER',
    Client: 'CLIENT',
}

const CreationTypes = {
    Series: 'SERIES',
    Documentary: 'DOCUMENTARY',
    Movie: 'MOVIE',
    Animation: 'ANIMATION',
}

const ReportTypes = {
    VOD: 'VOD',
    TV: 'TV',
}

const TaskTypes = {
    ViewImport: 'VIEW_IMPORT',
    CreationImport: 'CREATION_IMPORT',
    SocietyImport: 'SOCIETY_IMPORT',
    BonusImport: 'BONUS_IMPORT',
    UserImport: 'USER_IMPORT',
    ForeignImport: 'FOREIGN_IMPORT',
    Report: 'REPORT',
}

export {
    UserRoles,
    CreationTypes,
    ReportTypes,
    TaskTypes,
}