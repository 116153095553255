import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SelectInput from '../../components/SelectInput'
import { useMutation, useQuery } from '@apollo/client'
import { GetAuthorRoles, GetCreationDistributions, GetCreationTypes, GetPoints } from '../../graphql/queries'
import './style.scss'
import ActivityIndicator from '../../components/ActivityIndicator'
import Input from '../../components/Input'
import { AddCreationDistribution, AddPoints, EditCreation } from '../../graphql/mutations'
import Button from '../../components/Button'
import PointItem from '../../components/PointItem'
import { ReportTypes } from '../../util/const'

const pointTypes = [
    {
        label: 'TV',
        value: ReportTypes.TV,
    },
    {
        label: 'VOD',
        value: ReportTypes.VOD,
    },
]

const pointState = {
    category: null,
    type: 'TV',
    points: null,
    start: null,
    end: null,
}

const distState = {
    creationType: null,
    amount: null,
    role: null,
}

const ReportSettingsScreen = () => {

    const { t } = useTranslation()
    const [editCreation] = useMutation(EditCreation)
    const [createPoints] = useMutation(AddPoints)
    const [createDist] = useMutation(AddCreationDistribution)
    const [item, setItem] = useState(pointState)
    const [distItem, setDistItem] = useState(distState)
    const [types, setTypes] = useState([])
    const [roles, setRoles] = useState([])
    const [points, setPoints] = useState([])
    const [distributions, setDistributions] = useState([])

    const { data, refetch, loading } = useQuery(GetPoints, {
        onCompleted: (data) => {
            if (!data?.getPoints) return
            setPoints(data.getPoints)
        },
    })

    const { refetch: refetchDistributions } = useQuery(GetCreationDistributions, {
        onCompleted: (data) => {
            if (!data?.getCreationDistributions) return
            setDistributions(data.getCreationDistributions)
        },
    })

    useQuery(GetCreationTypes, {
        onCompleted: (data) => {
            if (!data?.getCreationTypes) return
            setTypes(data.getCreationTypes)
        },
    })

    useQuery(GetAuthorRoles, {
        onCompleted: (data) => {
            if (!data?.getAuthorRoles) return
            setRoles(data.getAuthorRoles)
        },
    })

    const setField = (field, value) => {
        setItem({
            ...item,
            [field]: value,
        })
    }

    const setDistField = (field, value) => {
        setDistItem({
            ...distItem,
            [field]: value,
        })
    }

    const changeType = async (type) => {
        setItem({
            ...item,
            type,
        })
        // await updateProperty('type', parseInt(type))
    }

    const addPoints = async () => {
        try {
            const data = {
                category: parseInt(item.category),
                type: item.type,
                points: parseInt(item.points),
            }

            if (item.start) data.start = parseInt(item.start)
            if (item.end) data.end = parseInt(item.end)

            console.log('adding ponts:', data)
            await createPoints({
                variables: {
                    data,
                },
            })

            setItem({
                ...pointState,
                category: item.category,
                type: item.type,
            })
            refetch()
        } catch (err) {
            console.log('addPoints: ', err)
        }
    }

    const addDist = async () => {
        try {
            const data = {
                creationType: parseInt(distItem.creationType),
                role: parseInt(distItem.role),
                amount: parseInt(distItem.amount),
            }

            console.log('adding dist:', data)
            await createDist({
                variables: data,
            })

            refetchDistributions()
        } catch (err) {
            console.log('addDist: ', err)
        }
    }

    const handleDistItemChange = (event, item) => {
        const itemIndex = distributions.findIndex(x => x.id === item.id)
        const list = [
            ...distributions,
        ]
        const obj = list[itemIndex]

        list[itemIndex] = {
            ...obj,
            amount: event.target.value,
        }

        setDistributions(list)
    }

    const displayTotal = (typeId) => {
        const total = distributions.filter(x => x.creationType === typeId).reduce((acc, curr) => acc + parseFloat(curr.amount), 0)
        return (
            <span className='total'>{ `${t('settings.total')}: ${total}%` }</span>
        )
    }

    if (loading) return <ActivityIndicator />

    return (
        <div className='settings'>
            <div className='inner'>
                <div className='inner-title'>
                    <h1>{ t('settings.title') }</h1>
                </div>
                <div className='settings-sections'>
                    <a href='#points'>{ t('settings.points_title') }</a>
                    <a href='#distribution'>{ t('settings.distribution_title') }</a>
                </div>
                <div className='settings-content'>
                    <h3 id="points">{ t('settings.points_title') }</h3>
                    <div className='settings-points'>
                        <div className='settings-points--table'>
                            {
                                points && points.length > 0 ?
                                <>
                                    <div className='settings-points-tv'>
                                        <h4>{ t('settings.points_tv') }</h4>
                                        {
                                            points.filter(x => x.type === 'TV').length > 0 ?
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            { t('settings.points') }
                                                        </th>
                                                        <th>
                                                            { t('settings.points_from') }
                                                        </th>
                                                        <th>
                                                            { t('settings.points_to') }
                                                        </th>
                                                        <th>
                                                            { t('settings.points_category') }
                                                        </th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    points.filter(x => x.type === 'TV').map((item) =>
                                                        <PointItem
                                                            key={item.id}
                                                            onRemove={refetch}
                                                            data={item}
                                                            types={types}
                                                        />
                                                    )
                                                }
                                                </tbody>
                                            </table>
                                            :
                                            <p>{ t('settings.no_points') }</p>
                                        }
                                    </div>
                                    <div className='settings-points-vod'>
                                        <h4>{ t('settings.points_vod') }</h4>
                                        {
                                            points.filter(x => x.type === 'VOD').length > 0 ?
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            { t('settings.points') }
                                                        </th>
                                                        <th>
                                                            { t('settings.points_from') }
                                                        </th>
                                                        <th>
                                                            { t('settings.points_to') }
                                                        </th>
                                                        <th>
                                                            { t('settings.points_category') }
                                                        </th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    points.filter(x => x.type === 'VOD').map((item) =>
                                                        <PointItem
                                                            key={item.id}
                                                            onRemove={refetch}
                                                            data={item}
                                                            types={types}
                                                        />
                                                    )
                                                }
                                                </tbody>
                                            </table>
                                            :
                                            <p>{ t('settings.no_points') }</p>
                                        }
                                    </div>
                                </>
                                :
                                <p>{ t('settings.no_points') }</p>
                            }
                        </div>
                        
                        <div className='settings-points--add'>
                            <h4>{ t('settings.add_points') }</h4>
                            <SelectInput
                                placeholder={t('settings.points_type')}
                                options={pointTypes}
                                value={pointTypes.find(x => x.value === item.type)}
                                onChange={(option) => setItem({ ...item, type: option.value })}
                            />
                            <div className='settings-points--add-between'>
                                <Input
                                    label={t('settings.points_from')}
                                    value={ item.start }
                                    suffix={ item.type === 'VOD' ? t('settings.points_count') : t('settings.points_minutes')}
                                    onChange={(e) => setItem({ ...item, start: e.target.value })}
                                />
                                <Input
                                    label={t('settings.points_to')}
                                    value={ item.end }
                                    suffix={ item.type === 'VOD' ? t('settings.points_count') : t('settings.points_minutes')}
                                    onChange={(e) => setItem({ ...item, end: e.target.value })}
                                />
                            </div>
                            <Input
                                label={t('settings.points')}
                                value={ item.points }
                                onChange={(e) => setItem({ ...item, points: e.target.value })}
                            />
                            <SelectInput
                                placeholder={t('settings.points_category')}
                                options={types}
                                value={types.find(x => x.id === item?.category)}
                                getOptionLabel={(option) => t(option.name)}
                                getOptionValue={(option) => option.id}
                                onChange={(option) => setItem({ ...item, category: option.id })}
                            />
                            <Button
                                label={t('settings.add_points')}
                                onClick={addPoints}
                            />
                        </div>
                    </div>
                </div>
                <h3 id="distribution">{ t('settings.distribution_title') }</h3>
                    <div className='settings-distribution'>
                        <div className='settings-distribution--table'>
                            {
                                distributions && types && distributions.length > 0 && types.length > 0 ?
                                types.map(x =>
                                    <div key={`dist-${x.id}`} className='settings-distribution--type'>
                                        <h5>{ t(x.name) }</h5>
                                        <div className='settings-distribution--items'>
                                            {
                                                distributions.filter(i => i.creationType === x.id).map(i =>
                                                    <div key={`dist-item-${i.id}`} className='settings-distribution--item'>
                                                        <span>{ t(roles.find(r => r.id === i.role)?.name) }</span>
                                                        <Input
                                                            type={'number'}
                                                            value={i.amount}
                                                            suffix={'%'}
                                                            onChange={(e) => handleDistItemChange(e, i)}
                                                        />
                                                    </div>
                                                )
                                            }
                                            { displayTotal(x.id) }
                                        </div>
                                    </div>    
                                )
                                :
                                <p>{ t('settings.no_distribution') }</p>
                            }
                        </div>
                        
                        <div className='settings-distribution--add'>
                            <h4>{ t('settings.add_distribution') }</h4>
                            <SelectInput
                                placeholder={t('settings.distribution_type')}
                                options={types}
                                getOptionLabel={(option) => t(option.name)}
                                getOptionValue={(option) => option.id}
                                value={types.find(x => x.id === distItem.type)}
                                onChange={(option) => setDistField('creationType', option.id)}
                            />
                            <SelectInput
                                placeholder={t('settings.distribution_role')}
                                options={roles}
                                value={roles.find(x => x.id === distItem.role)}
                                getOptionLabel={(option) => t(option.name)}
                                getOptionValue={(option) => option.id}
                                onChange={(option) => setDistField('role', option.id)}
                            />
                            <Input
                                label={t('settings.distribution_amount')}
                                value={ distItem.amount }
                                type={'number'}
                                suffix={'%'}
                                onChange={(e) => setDistField('amount', e.target.value)}
                            />
                            <Button
                                label={t('settings.add_distribution')}
                                onClick={addDist}
                            />
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default ReportSettingsScreen