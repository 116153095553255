import { t } from 'i18next'
import React from 'react'
import ChevronIcon from './icons/chevron'

const Pagination = ({ total, onNext, onPrev, current, limit }) => {

  const getTotalPages = () => {
    return Math.ceil(total / limit)
  }

  const getCurrentPage = () => {
    return current + 1
  }

  if (!total || total <= 0) return <></>

  return (
    <div className="pagination">
      <div className={`pagination-prev ${getCurrentPage() <= 1 ? 'disabled' : ''}`} onClick={onPrev}>
        <ChevronIcon />
        <span>{ t('Eelmised') }</span>
      </div>
      <div className="pagination-pages">
        <span>{ `${getCurrentPage()}` }</span>
        <span className="pagination-separator">/</span>
        <span>{ `${getTotalPages()}` }</span>
      </div>
      <div className={`pagination-next ${getCurrentPage() >= getTotalPages() ? 'disabled' : ''}`} onClick={onNext}>
        <span>{ t('Järgmised') }</span>
        <ChevronIcon />
      </div>
    </div>
  )
}

export default Pagination