import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from './Button'
import Input from './Input'
import { useAuth } from '../providers/Auth'
import { useMutation } from '@apollo/client'
import { Login, ResetPassword } from '../graphql/mutations'

const initialCredentials = {
    email: '',
    password: '',
}

const UserLogin = ({
    onCancel,
}) => {

    const { t } = useTranslation()
    const { user, signIn } = useAuth()
    const [error, setError] = useState()
    const [forgotError, setForgotError] = useState()
    const [forgot, setForgot] = useState()
    const [forgotSent, setForgotSent] = useState()
    const [credentials, setCredentials] = useState(initialCredentials)
    const [resetPass] = useMutation(ResetPassword)
    const [loginWithEmail] = useMutation(Login)

    useEffect(() => {

        return () => {
            reset()
        }
    }, [])

    const reset = () => {
        setError(null)
    }

    const login = async () => {
        try {
            const res = await loginWithEmail({
                variables: {
                    email: credentials.email,
                    password: credentials.password,
                },
            })

            if (res?.data?.login?.token) {
                signIn(res.data.login.token)
                return
            }

            setError(t('login.error'))
        } catch (err) {
            setError(t('login.error'))
            console.log('login: ', err)
        }
    }

    const resetPassword = async () => {
        try {
            const res = await resetPass({
                variables: {
                    email: credentials.email,
                },
            })

            if (res?.resetPassword?.status === 'FAIL') {
                setForgotError(true)
                return
            }

            setForgotSent(true)
        } catch (err) {
            setForgotError(true)
            console.log('resetPassword: ', err)
        }
    }

    const handleCancel = () => {
        if (onCancel) onCancel()
    }

    const handleCancelForgot = () => {
        setForgotError(null)
        setForgotSent(false)
        setForgot(false)
    }

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') login()
    }

    const renderError = () => {
        return (
            <p>
                { error }
            </p>
        )
    }

    const renderForgot = () => {
        return (
            <div className='login-method login-method--user-forgot'>
                {
                    forgotSent ?
                    <div className='login-method--user-forgot-sent'>
                        <p>{ t('login.forgot_sent') }</p>
                        <Button
                            className={'btn-cancel'}
                            label={t('login.back')}
                            onClick={handleCancelForgot}
                        />
                    </div>
                    :
                    <div className='login-method--input'>
                        <Input
                            label={ t('login.email') }
                            onChange={(e) => setCredentials({ ...credentials, email: e.target.value })}
                            value={credentials.email}
                            type={'email'}
                        />
                        {
                            forgotError ?
                            <p>{ t('login.forgot_error') }</p>
                            :
                            <></>
                        }
                        <div className='login-actions'>
                            <Button
                                className={'btn-cancel'}
                                label={t('login.cancel')}
                                onClick={handleCancelForgot}
                            />
                            <Button
                                label={t('login.reset_password')}
                                onClick={resetPassword}
                            />
                        </div>
                    </div>
                }
            </div>
        )
    }

    return (
        <>
        {
            forgot ?
            renderForgot()
            :
            <div className='login-method login-method--user'>
                <div className='login-method--input'>
                    <Input
                        label={ t('login.email') }
                        onChange={(e) => setCredentials({ ...credentials, email: e.target.value })}
                        value={credentials.email}
                        type={'email'}
                    />
                    <Input
                        label={ t('login.password') }
                        type={'password'}
                        value={credentials.password}
                        onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
                        onKeyUp={handleKeyUp}
                    />
                    {
                    error ?
                        <div className='login-method--error'>
                            {
                                renderError()
                            }
                        </div>
                        :
                        <></>
                    }
                    <div className='login-actions'>
                        <Button
                            className={'btn-cancel'}
                            label={t('login.cancel')}
                            onClick={handleCancel}
                        />
                        <Button
                            label={t('login.enter')}
                            onClick={login}
                        />
                    </div>
                    <a onClick={() => setForgot(true)}>{ t('login.forgot_password') }</a>
                </div>
            </div>
        }
        </>
    )
}

export default UserLogin