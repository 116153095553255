const secondsToHms = (seconds) => {
    seconds = Number(seconds)
    const h = Math.floor(seconds / 3600)
    const m = Math.floor(seconds % 3600 / 60)
    const s = Math.floor(seconds % 3600 % 60)

    return {
        h: h > 0 ? h : 0,
        m: m > 0 ? m : 0,
        s: s > 0 ? s : 0,
    }
}

const hmsToSeconds = (hms) => {
    const parts = hms.split(':')
    return (+parts[0]) * 60 * 60 + (+parts[1]) * 60 + (+parts[2])
}

export {
    secondsToHms,
    hmsToSeconds,
}