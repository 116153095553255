import React from 'react'
import ExclamationIcon from './icons/exclamation'

const ErrorMessage = ({
    message,
}) => {
    if (!message) return <></>
    return (
        <div className='error-message--container'>
            <div className='error-message--message'>
                <ExclamationIcon />
                <span>{ message }</span>
            </div>
        </div>
    )
}

export default ErrorMessage