import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import Button from './Button'
import Input from './Input'
import Modal from './Modal'
import { AddSociety } from '../graphql/mutations'

const initialState = {
    name: '',
    code: '',
    country: '',
}

const AddSocietyModal = ({
    show,
    close,
    onSuccess,
}) => {

    const { t } = useTranslation()
    const [item, setItem] = useState(initialState)
    const [error, setError] = useState()
    const [add] = useMutation(AddSociety)

    const handleAdd = async () => {
        try {
            const res = await add({
                variables: {
                    data: {
                        ...item,
                        code: parseInt(item.code),
                    },
                },
            })

            if (!res?.data?.addSociety || res.data.addSociety === 'FAIL') {
                setError(t('societies.add_error'))
                return
            }

            if (res.data.addSociety === 'EXISTS') {
                setError(t('societies.existing_error'))
                return
            }

            if (onSuccess) onSuccess(res.data.addSociety)
        } catch (err) {
            setError(t('societies.add_error'))
            console.log(err)
        }
    }

    const handleClose = () => {
        if (close) close()
        setError(null)
        setItem(initialState)
    }

    return (
        <Modal
            className={'modal-society'}
            show={show}
            close={handleClose}
            title={ t('societies.add_new') }
        >
            <Input
                label={ t('societies.name') }
                value={item.name}
                onChange={(e) => setItem({ ...item, name: e.target.value })}
            />
            <Input
                label={ t('societies.code') }
                value={item.code}
                type={'number'}
                onChange={(e) => setItem({ ...item, code: e.target.value.length > 2 ? item.code : e.target.value })}
            />
            <Input
                label={ t('societies.country') }
                value={item.country}
                onChange={(e) => setItem({ ...item, country: e.target.value })}
            />

            {
                error ?
                <div className='error-message'>
                    <span>{ error }</span>
                </div>
                :
                <></>
            }

            <Button
                label={ t('societies.add_new') }
                onClick={handleAdd}
            />
        </Modal>
    )
}

export default AddSocietyModal