import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import Textarea from './Textarea'
import Modal from './Modal'
import { SendEmailToUsers } from '../graphql/mutations'
import Button from './Button'
import Input from './Input'
import EmailSentIcon from './icons/mail-sent'
import ErrorMessage from './ErrorMessage'

const SendMailModal = ({
    show,
    close,
    onSuccess,
}) => {

    const { t } = useTranslation()
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState()
    const [subject, setSubject] = useState('')
    const [content, setContent] = useState('')
    const [sendEmail] = useMutation(SendEmailToUsers)

    const handleSend = async () => {
        setLoading(true)
        
        try {
            const res = await sendEmail({
                variables: {
                    subject,
                    content,
                    ids: [],
                },
            })

            if (res?.data?.sendEmailToUsers === 'SUCCESS') {
                setSuccess(true)
                if (onSuccess) onSuccess()
                return
            }

            setError(t('users.email_error'))
        } catch (err) {
            setError(t('users.email_error'))
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const handleClose = () => {
        if (close) close()
        setError(null)
        setSubject('')
        setContent('')
        setSuccess(null)
    }

    return (
        <Modal
            className={'modal-email'}
            show={show}
            close={handleClose}
            title={ success ? '' : t('users.send_mail_all') }
        >
            {
                success ?
                <div className='modal-email--success'>
                    <div>
                        <EmailSentIcon />
                    </div>
                    <h4>{ t('users.email_sent') }</h4>
                </div>
                :
                <>
                    <Input
                        label={ t('users.email_subject') }
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    />
                    <Textarea
                        label={ t('users.email_content') }
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                    />

                    <Button
                        label={ t('users.send_mail') }
                        onClick={handleSend}
                        loading={loading}
                        disabled={loading || !subject || !content}
                    />
                    <ErrorMessage
                        message={error}
                    />
                </>
            }
        </Modal>
    )
}

export default SendMailModal